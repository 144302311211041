import React from "react";
import Title from "../components/Title";

const PrivacyPolicy = () => {
  return (
    <>
      <Title text="PRIVACY POLICY" />

      <div className="w-full flex items-center justify-center font-montserrat text-grey_1 mb-20">
        <div className="w-2/3 lg:text-base text-sm">
          This Privacy Policy discloses the privacy practices for the Somisomi
          Website and various related services (together referred to as the
          “Site”). Somisomi, the provider of the Site (referred to as
          “Somisomi,” “us” or “we”), is committed to protecting your privacy
          online. Please read the information below to learn the following
          regarding your use of this Site:
          <br />
          <br />
          You acknowledge that this Privacy Policy is part of our Site Terms of
          Use, and by accessing or using our Site, you agree to be bound by all
          of its terms and conditions. If you do not agree to these terms,
          please do not access or use this site.
          <br />
          <br />
          We reserve the right to change this Privacy Policy at any time. Such
          changes, modifications, additions or deletions shall be effective
          immediately upon notice thereof, which may be given by means
          including, but not limited to issuing an e-mail to the e-mail address
          listed by registered users and posting the revised Policy on this
          page. You acknowledge and agree that it is your responsibility to
          maintain a valid e-mail address as a registered user, review this Site
          and this Policy periodically and to be aware of any modifications.
          Your continued use of the Site after such modifications will
          constitute your: (a) acknowledgment of the modified Policy; and (b)
          agreement to abide and be bound by the modified Policy.
          <br />
          <br />
          This Privacy Policy does not apply to the practices of companies that
          Somisomi does not own or control, or to individuals whom Somisomi does
          not employ or manage, including any of the third parties to which
          Somisomi may disclose user information as set forth in this Policy.
          <br />
          <br />
          Collecting Information
          <br />
          <br />
          1.1. We may collect, store and use two types of information about our
          users: Personally Identifiable Information and Non-Personally
          Identifiable Information. Our primary goal in collecting information
          from you is to provide you with a smooth, efficient, and customized
          experience while using our Site.
          <br />
          <br />
          Personally Identifiable Information refers to information that lets us
          know the specifics of who you are. When you engage in certain
          activities on this Site, such as registering for a membership,
          ordering a product or service, submitting content and/or posting
          content in discussion forums or other public areas, entering a contest
          or sweepstakes, filling out a survey, or sending us feedback, we may
          ask you to provide certain information about yourself. If you elect to
          engage in these activities, we may ask that you provide us personal
          information, such as your name, mailing address, e-mail address,
          employment, telephone and facsimile numbers, and other personal
          identifying information. When ordering products or services on the
          Site, you may be asked to provide a credit card number. Depending upon
          the activity, some of the information we ask you to provide is
          identified as mandatory and some as voluntary. If you do not provide
          the mandatory data with respect to a particular activity, you will not
          be able to engage in that activity.
          <br />
          <br />
          Non-Personally Identifiable Information refers to information that
          does not by itself identify a specific individual. We gather certain
          information about you based upon where you visit on our Site in
          several ways. This information is compiled and analyzed on both a
          personal and an aggregated basis. This information may include the
          Site’s Uniform Resource Locator (“URL”) that you just came from, which
          URL you go to next, what browser you are using, and your Internet
          Protocol (“IP”) address.
          <br />
          <br />
          Using Your Information
          <br />
          <br />
          2.1. When you register for services or purchase any product from
          Somisomi, we may ask for Personally Identifiable Information such as
          your email address, billing address, and credit card information.
          Credit card information is only used in processing your transaction.
          Please note that we may use a third party payment processing merchant
          to process your payments
          <br />
          <br />
          2.2. We may use your information to process any transaction, inquiry
          or promotion that you initiate with us, and provide you with
          advertising tailored to your interests. We maintain a record of your
          product interests, purchases and whatever else might enable us or our
          business partners to enhance and personalize your shopping experience
          on this or other websites and to provide you with offers, promotions
          or information that we believe may be of interest to you. In addition,
          we monitor use of the Site and traffic patterns to improve the Site
          design and the products and services we offer as well as to determine
          what offers, promotions or information to send to you. We use the
          information obtained from you to provide better customer service,
          enhance our product offerings, and detect potential misuse or fraud.
          <br />
          <br />
          2.3. We may use both Personally Identifiable Information and
          Non-Personally Identifiable Information to enhance the operation of
          our Site, fill orders, improve our marketing and promotional efforts,
          statistically analyze site use, improve our product and service
          offerings, and customize our Site’s content, layout, and services. We
          may use Personally Identifiable Information to deliver information to
          you and to contact you regarding administrative notices. We may also
          use Personally Identifiable Information to resolve disputes,
          troubleshoot problems and enforce our agreements with you, including
          our Site Terms of Use, this Private Policy, and any other applicable
          policies.
          <br />
          <br />
          Disclosing Personal Information
          <br />
          <br />
          3.1. We may disclose your information to any of our employees or
          officers insofar as reasonably necessary for the purposes set out in
          this policy. We may also disclose your information to any member of
          our group of companies (this means our subsidiaries, our ultimate
          holding company and all its subsidiaries) insofar as reasonably
          necessary for the purposes set out in this policy.
          <br />
          <br />
          3.2. We may engage third party companies and individuals to perform
          services on our behalf (e.g., without limitation, software maintenance
          services, payment processing services, e-mail service providers,
          delivery services, database management, web analytics and other
          services). These third parties may have access to your Personal
          Information.
          <br />
          <br />
          3.3. We may disclose your information:
          <ul className="list-disc pl-10">
            <li>to the extent that we are required to do so by law;</li>
            <li>
              in connection with any ongoing or prospective legal proceedings;
            </li>
            <li>
              in order to establish, exercise or defend our legal rights
              (including providing information to others for the purposes of
              fraud prevention and reducing credit risk);
            </li>
            <li>
              to the purchaser (or prospective purchaser) of any business or
              asset that we are (or are contemplating) selling; and
            </li>
            <li>
              to any person who we reasonably believe may apply to a court or
              other competent authority for disclosure of that personal
              information where, in our reasonable opinion, such court or
              authority would be reasonably likely to order disclosure of that
              personal information.
            </li>
          </ul>
          <br />
          3.4. We do not sell, trade, or rent your Personally Identifiable
          Information to others. We do provide some of our services through
          contractual arrangements with affiliates, services providers, partners
          and other third parties. We will encourage our service partners to
          adopt and post privacy policies. However, the use of your Personally
          Identifiable Information by our service partners is governed by the
          privacy policies of those service partners, and is not subject to our
          control. We may provide Non-Personally Identifiable Information about
          your traffic patterns, purchases and related site information to third
          party advertisers, but these statistics do not include any Personally
          Identifiable Information.
          <br />
          <br />
          3.5. Somisomi uses third party payment processors to process financial
          payments for certain Somisomi services. When you make payments to
          Somisomi and/or obtain certain Somisomi products or services, Somisomi
          may transfer certain Information (including Personally Identifying
          Information) to these payment processors for the sole purpose of
          facilitating the payment transaction, such as your name, address, and
          credit card information.
          <br />
          <br />
          3.6. Somisomi’s website services are hosted by http://wix.com, an
          enterprise web publishing platform. All Information obtained and
          stored by Somisomi’s Site is processed and stored on servers that are
          owned and operated by http://wix.com
          <br />
          <br />
          3.7. We also use Google Analytics to help us understand how our
          customers use the Site--you can read more about how Google uses your
          Personal Information{" "}
          <a
            className="underline"
            href="https://www.google.com/intl/en/policies/privacy"
          >
            here
          </a>
          . You can also opt-out of Google Analytics{" "}
          <a
            className="underline"
            href="https://tools.google.com/dlpage/gaoptout"
          >
            here
          </a>
          .
          <br />
          <br />
          3.8. As described, we use your Personal Information to provide you
          with targeted advertisements or marketing communications we believe
          may be of interest to you. For more information about how targeted
          advertising works, you can visit the Network Advertising Initiative’s
          (“NAI”) educational page{" "}
          <a
            className="underline"
            href="http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work"
          >
            here
          </a>
          .
          <br />
          <br />
          You can opt out of targeted advertising by editing your account
          settings on each platform:
          <ul className="pl-10">
            <li className="underline">
              <a href="https://www.facebook.com/settings/?tab=ads">Facebook</a>
            </li>
            <li className="underline">
              <a href="https://www.google.com/settings/ads/anonymous">Google</a>
            </li>
            <li className="underline">
              <a href="https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads">
                Bing
              </a>
            </li>
            <li className="underline">
              <a href="https://help.pinterest.com/en/articles/edit-your-settings#Web">
                Pinterest
              </a>
            </li>
          </ul>
          <br />
          Additionally, you can opt out of some of these services by visiting
          the Digital Advertising Alliance’s opt-out portal{" "}
          <a className="underline" href="http://optout.aboutads.info/">
            here
          </a>
          .
          <br />
          <br />
          International Data Transfers
          <br />
          <br />
          4.1. Information that we collect may be stored and processed in and
          transferred between any of the countries in which we operate in order
          to enable us to use the information in accordance with this policy.
          <br />
          <br />
          Security of Your Personal Information
          <br />
          <br />
          5.1. We will take reasonable technical and organizational precautions
          to prevent the loss, misuse or alteration of your personal
          information.
          <br />
          <br />
          5.2. We will store all the personal information you provide on our
          secure (password- and firewall-protected) servers.
          <br />
          <br />
          5.3. All electronic financial transactions entered into through our
          Site will be protected by encryption technology.
          <br />
          <br />
          5.4. You acknowledge that the transmission of information over the
          Internet is inherently insecure, and we cannot guarantee the security
          of data sent over the internet. While we strive to protect your
          Personally Identifiable Information, you acknowledge that: (a) there
          are security and privacy limitations of the Internet which are beyond
          our control; (b) the security, integrity and privacy of any and all
          information and data exchanged between you and us through this site
          cannot be guaranteed; and (c) any such information and data may be
          viewed or tampered with in transit by a third party.
          <br />
          <br />
          5.5. You are responsible for keeping the password you use for
          accessing our Site confidential; we will not ask you for your password
          (except when you log in to our Site).
          <br />
          <br />
          5.6. Notice to California Residents. Subject to certain limits under
          California Civil Code § 1798.83, California residents may ask us to
          provide them with (i) a list of certain categories of personal
          information that we have disclosed to third parties for their direct
          marketing purposes during the immediately preceding calendar year, and
          (ii) the identity of those third parties. To make this request,
          California residents may contact us as specified in the "Contact Us"
          section. If you are a California resident, California law may provide
          you with additional rights regarding our use of your personal
          information. Please refer to CCPA Privacy Policy [link here].
          <br />
          <br />
          5.7. Notice to European Residents. If you are a European resident, you
          have the right to access personal information we hold about you and to
          ask that your personal information be corrected, updated, or deleted.
          If you would like to exercise this right, please contact us at the
          contact specified in section 7.1.
          <br />
          <br />
          Additionally, if you are a European resident we note that we are
          processing your information in order to fulfill contracts we might
          have with you (for example if you make an order through the Site), or
          otherwise to pursue our legitimate business interests listed above.
          Additionally, please note that your information will be transferred
          outside of Europe, including to Canada and the United States.
          <br />
          <br />
          Amendments
          <br />
          <br />
          6.1. We may update this policy from time to time by publishing a new
          version on our website.
          <br />
          <br />
          6.2. You should check this page occasionally to ensure you are happy
          with any changes to this policy.
          <br />
          <br />
          6.3. We may notify you of changes to this policy by email or through
          the private messaging system on our website.
          <br />
          <br />
          Updating information
          <br />
          <br />
          7.1. You may change any of your Personally Identifiable Information in
          your account online at any time by linking to your account. You may
          also access and correct your personal information and privacy
          preferences by writing us at:
          <br />
          <br />
          Somisomi Franchise, Inc.
          <br />
          621 S. Western Ave., Suite 208-A
          <br />
          Los Angeles, CA 90005
          <br />
          <br />
          213-435-7188
          <br />
          <br />
          info@somisomi.com
          <br />
          <br />
          Cookies
          <br />
          <br />
          8.1. Our website uses cookies. A cookie is a file containing an
          identifier (a string of letters and numbers) that is sent by a web
          server to a web browser and is stored by the browser. The identifier
          is then sent back to the server each time the browser requests a page
          from the server. We use cookies on our Site to recognize a computer
          when a user visits the Site, enable the use of a shopping cart on the
          Site to improve the website's usability, analyses the use of the Site,
          track user navigation on the web and associated mobile app, administer
          the Site, prevent fraud and improve the security of the Site. The
          cookies make your use of the Site easier, make the site run more
          smoothly and help us to maintain a secure site.
          <br />
          <br />
          8.2. You are always free to decline our cookies if your browser
          permits, but some parts of our Site may not work properly in that
          case.
          <br />
          <br />
          8.3. We may use an outside ad serving company to display banner
          advertisements on our Site. As part of their service, they will place
          a separate cookie on your computer. We will not provide any
          third-party ad server with any of your Personally Identifiable
          Information or information about your purchases. We and our third
          party ad server will collect and use Non-Personally Identifiable
          Information about you, such as your IP address, browser type, the
          server your computer is logged onto, the area code and zip code
          associated with your server and whether you responded to a particular
          ad. Other advertisers may also place banner ads on our Site in the
          same manner as above, but we will not disclose any Personally
          Identifiable Information to them.
          <br />
          <br />
          Other Information
          <br />
          <br />
          9.1 This website is owned and operated by Somisomi Franchise, Inc. You
          may contact us by writing to the business address given above, by
          using “Contact Us” [link].
          <br />
          <br />
          9.2. Except as otherwise discussed in this Privacy Policy, this
          document only addresses the use and disclosure of information we
          collect from you. Other sites accessible through our Site have their
          own privacy policies and data collection, use and disclosure
          practices. Please consult each site’s privacy policy. We are not
          responsible for the policies or practices of third parties.
          Additionally, other companies which place advertising on our site may
          collect information about you when you view or click on their
          advertising through the use of cookies. We cannot control this
          collection of information. You should contact these advertisers
          directly if you have any questions about their use of the information
          that they collect.
          <br />
          <br />
          9.3. Our Website is not directed at children. You must be at least 13
          years old to have our permission to use this site. Our policy is that
          we do not knowingly collect, use or disclose Personally Identifiable
          Information about visitors that are under 13 years of age.
          <br />
          <br />
          9.4. You should also be aware that when Personally Identifiable
          Information is voluntarily disclosed (i.e. your name, e-mail address,
          etc.) in the discussion forums or other public areas on this site,
          that information, along with any information disclosed in your
          communication, can be collected and used by third parties and may
          result in unsolicited messages from third parties. Such activities are
          beyond our control and this Policy does not apply to such information.
          Any submissions to chat rooms or other public areas on this site are
          accepted with the understanding that they are accessible to all third
          parties. If you do not want your comments to be viewed by third
          parties, you are advised not to make any submissions. Ultimately, you
          are solely responsible for maintaining the secrecy of your password
          and/or account information. Please be careful and responsible whenever
          you are online.
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
