import React, { useState } from "react";

const LocationDropdown = ({ selectedLocation, onSelect }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const locations = [
    "Bella Terra",
    "Bellevue",
    "Carrollton",
    "Chandler",
    "Convoy",
    "Cupertino",
    "Del Amo",
    "Diamond Jamboree",
    "Elk Grove",
    "Frisco",
    "Fullerton",
    "Garden Grove",
    "Henderson",
    "Irvine Spectrum",
    "Katy",
    "Koreatown",
    "Las Vegas",
    "Little Tokyo",
    "Long Beach",
    "Milpitas",
    "Mira Mesa",
    "Natomas",
    "Ontario Mills",
    "Palo Alto",
    "Pleasanton",
    "Sacramento",
    "San Francisco",
    "San Jose",
    "San Mateo",
    "Santa Clara",
    "Seattle",
    "Sugar Land",
    "UTC",
    "Westheimer",
  ];

  return (
    <div className="flex flex-col md:w-2/3 w-11/12 md:mb-14 mb-4 border-b-2 border-purple_2 md:pb-14 pb-4">
      <h1 className="text-xl text-grey_3 font-bilagro md:mb-10 mb-4">SELECT A LOCATION</h1>

      <button
        onClick={() => setShowDropdown(!showDropdown)}
        type="button"
        className="bg-purple_3 py-5 px-6 rounded-3xl text-purple_1 w-full flex flex-row justify-between focus:outline-none"
      >
        {selectedLocation ? selectedLocation : "Select a location"}
        <svg
          className="-mr-1 ml-2 h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>

      {/* OPTIONS */}
      {showDropdown && (
        <div className="bg-purple_3 text-grey_1 rounded-3xl py-5 px-4 mt-7 h-[17.3rem] overflow-y-scroll flex flex-col justify-between ">
          {locations.map((location, index) => (
            <div
              key={index}
              onClick={() => {
                onSelect(location);
                setShowDropdown(!showDropdown);
              }}
              className={
                selectedLocation === location
                  ? "px-5 py-3 bg-purple_2 rounded-xl text-purple_1 cursor-pointer"
                  : "px-5 py-3 hover:bg-purple_2 hover:rounded-xl hover:text-purple_1 cursor-pointer"
              }
            >
              {location}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LocationDropdown;
