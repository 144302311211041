import React from "react";
import Title from "../components/Title";

const CCPA = () => {
  return (
    <>
      <Title text="CCPA NOTICE FOR CALIFORNIA CONSUMERS" />

      <div className="w-full flex items-center justify-center font-montserrat text-grey_1 mb-20">
        <div className="w-2/3 lg:text-base text-sm">
          Under the California Consumer Privacy Act (“CCPA”), we are required to
          provide certain information to California consumers about how we
          handle their personal information that we have collected, whether
          online or offline. For purposes of this section of our privacy policy,
          and per the CCPA, “personal information” is broadly defined to include
          any information that identifies, relates to, describes, is capable of
          being associated with, or could reasonably be linked, directly or
          indirectly, with a particular consumer or household, including the
          categories identified in the table below to the extent they identify,
          relate to, describe, are capable of being associated with, or could be
          reasonably linked, directly or indirectly, with a particular consumer
          or household.
          <br />
          <br />
          Categories of Personal Information that We Collect, Disclose, and Sell
          <br />
          <br />
          The table below discloses the categories of personal information, as
          defined by CCPA, about California consumers that we collect, sell, and
          disclose for a business purpose. Since this section also includes
          offline data, this section may be broader than the disclosures set
          forth above.
          <br />
          <br />
          Category
          <br />
          <br />
          A. Identifiers.
          <br />
          EXAMPLE: A real name, alias, postal address, unique personal
          identifier, online identifier, Internet Protocol address, email
          address, account name, Social Security number, driver's license
          number, passport number, or other similar identifiers. <br />
          COLLECTED?: YES
          <br />
          <br />
          B. Personal information categories listed in the California Customer
          Records statute (Cal. Civ. Code § 1798.80(e)).
          <br />
          EXAMPLE: A name, signature, Social Security number, physical
          characteristics or description, address, telephone number, passport
          number, driver's license or state identification card number,
          insurance policy number, education, employment, employment history,
          bank account number, credit card number, debit card number, or any
          other financial information, medical information, or health insurance
          information. Some personal information included in this category may
          overlap with other categories. <br />
          COLLECTED?: YES
          <br />
          <br />
          C. Protected classification characteristics under California or
          federal law.
          <br />
          EXAMPLE: Age (40 years or older), race, color, ancestry, national
          origin, citizenship, religion or creed, marital status, medical
          condition, physical or mental disability, sex (including gender,
          gender identity, gender expression, pregnancy or childbirth and
          related medical conditions), sexual orientation, veteran or military
          status, genetic information (including familial genetic information).
          <br />
          COLLECTED?: NO
          <br />
          D. Commercial information.
          <br />
          EXAMPLE: Records of personal property, products or services purchased,
          obtained, or considered, or other purchasing or consuming histories or
          tendencies.
          <br />
          COLLECTED?: NO
          <br />
          <br />
          E. Biometric information.
          <br />- Genetic, physiological, behavioral, and biological
          characteristics, or activity patterns used to extract a template or
          other identifier or identifying information, such as, fingerprints,
          faceprints, and voiceprints, iris or retina scans, keystroke, gait, or
          other physical patterns, and sleep, health, or exercise data.
          <br />
          COLLECTED?: NO
          <br />
          <br />
          F. Internet or other similar network activity.
          <br />
          EXAMPLE: Browsing history, search history, information on a consumer's
          interaction with a website, application, or advertisement.
          <br />
          COLLECTED?: NO
          <br />
          <br />
          G. Geolocation data.
          <br />
          EXAMPLE: Physical location or movements.
          <br />
          COLLECTED?: YES
          <br />
          <br />
          H. Sensory data.
          <br />
          EXAMPLE: Audio, electronic, visual, thermal, olfactory, or similar
          information.
          <br />
          COLLECTED?: NO
          <br />
          I. Professional or employment-related information.
          <br />
          EXAMPLE: Current or past job history or performance evaluations.
          <br />
          COLLECTED?: NO
          <br />
          <br />
          J. Non-public education information (per the Family Educational Rights
          and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).
          <br />
          EXAMPLE: Education records directly related to a student maintained by
          an educational institution or party acting on its behalf, such as
          grades, transcripts, class lists, student schedules, student
          identification codes, student financial information, or student
          disciplinary records.
          <br />
          COLLECTED?: NO
          <br />
          <br />
          K. Inferences drawn from other personal information.
          <br />
          EXAMPLE: Profile reflecting a person's preferences, characteristics,
          psychological trends, predispositions, behavior, attitudes,
          intelligence, abilities, and aptitudes.
          <br />
          COLLECTED?: NO
          <br />
          <br />
          Personal information does not include:
          <br />
          <ul className="list-disc pl-10">
            <li>Publicly available information from government records.</li>
            <li>De-identified or aggregated consumer information.</li>
            <li>
              Information excluded from the CCPA's scope, like:
              <ul style={{ listStyleType: "circle" }} className="pl-10">
                <li>
                  health or medical information covered by the Health Insurance
                  Portability and Accountability Act of 1996 (HIPAA) and the
                  California Confidentiality of Medical Information Act (CMIA)
                  or clinical trial data;
                </li>
                <li>
                  personal information covered by certain sector-specific
                  privacy laws, including the Fair Credit Reporting Act (FRCA),
                  the Gramm-Leach-Bliley Act (GLBA) or California Financial
                  Information Privacy Act (FIPA), and the Driver's Privacy
                  Protection Act of 1994.
                </li>
              </ul>
            </li>
          </ul>
          <br />
          We obtain the categories of personal information listed above from the
          following categories of sources:
          <br />
          <ul className="list-disc pl-10">
            <li>
              Directly from our customers or their agents. For example, from
              information that you provide to us related to the Services.
            </li>
            <li>
              Indirectly from our customers or their agents. For example,
              through information we collect from our customers in the course of
              providing Services to them.
            </li>
            <li>
              Directly and indirectly from activity on our website. For example,
              from submissions through our website portal or website usage
              details collected automatically.
            </li>
            <li>
              From third-parties that interact with us in connection with the
              services we perform. For example, from outside companies we may
              use to assist us in providing you with Services.
            </li>
          </ul>
          <br />
          Use of Personal Information
          <br />
          We may use or disclose the personal information we collect for one or
          more of the following business purposes:
          <br />
          <ul className="list-disc pl-10">
            <li>
              To meet or fulfill the reason you provided the information to us.
            </li>
            <li>
              To communicate with you about the Services, including Service
              announcements, updates or offers.
            </li>
            <li>To provide support and assistance for the Services.</li>
            <li>To create and manage your Account or other user profiles.</li>
            <li>
              To personalize website content and communications based on your
              preferences.
            </li>
            <li>To process orders or other transactions.</li>
            <li>To respond to user inquiries and fulfill user requests.</li>
            <li>
              To improve and develop the Services, including testing, research,
              analysis and product development.
            </li>
            <li>
              To protect against or deter fraudulent, illegal or harmful actions
              and maintain the safety, security and integrity of our Services.
            </li>
            <li>
              To comply with our legal or contractual obligations, resolve
              disputes, and enforce our Terms of Use.
            </li>
            <li>
              To respond to law enforcement requests and as required by
              applicable law, court order, or governmental regulations.
            </li>
            <li>
              For any other business purpose stated when collecting your
              Personal Information or as otherwise set forth in applicable data
              privacy laws, such as the California Consumer Privacy Act
              (“CCPA”).
            </li>
          </ul>
          <br />
          We will not collect additional categories of Personal Information or
          use the Personal Information we collected for materially different,
          unrelated, or incompatible purposes without providing you notice.
          <br />
          <br />
          As noted in the list above, we may communicate with you if you’ve
          provided us the means to do so. For example, if you’ve given us your
          email address, we may send you promotional email offers or email you
          about your use of the Services. Also, we may receive a confirmation
          when you open an email from us, which helps us improve our Services.
          If you do not want to receive communications from us, please indicate
          your preference by emailing us at info@somisomi.com
          <br />
          <br />
          Sharing Personal Information
          <br />
          <br />
          We may disclose your personal information to a third party for a
          business purpose. When we disclose personal information for a business
          purpose, we enter a contract that describes the purpose and requires
          the recipient to both keep that personal information confidential and
          not use it for any purpose except performing the contract.
          <br />
          <br />
          In the preceding twelve (12) months, we have disclosed the following
          categories of personal information for a business purpose:
          <br />
          <br />
          We disclose your personal information for a business purpose to the
          following categories of third parties:
          <br />
          <br />
          In the preceding twelve (12) months, we have not sold any personal
          information.
          <br />
          <br />
          California Consumer Rights
          <br />
          <br />
          If you are a California resident, you have certain rights related to
          your California Personal Information.
          <br />
          <ul className="list-disc pl-10">
            <li>
              Section 100 Rights. You have the right to request that we disclose
              to you the specific species of personal information we have
              collected.
            </li>
            <li>
              Section 105 Rights. You have the right to request that we delete
              California Personal Information about you which we have collected
              from you.
            </li>
            <li>
              Section 110 Rights. You may request that we disclose to you:
              <ul style={{ listStyleType: "circle" }} className="pl-10">
                <li>
                  the categories of California Personal Information we have
                  collected about you;
                </li>
                <li>
                  the categories of sources from which the California Personal
                  Information is collected;
                </li>
                <li>
                  our business or commercial purpose for collecting or selling
                  California Personal Information;
                </li>
                <li>
                  the categories of third parties with whom we share California
                  Personal Information; and
                </li>
                <li>
                  the specific pieces of information we have collected about
                  you.
                </li>
              </ul>
            </li>
            <li>
              Section 115 Rights. To the extent that we sell your California
              Personal Information within the meaning of the California Consumer
              Privacy Act or disclose such information for a business purpose,
              you may request that we disclose to you:
              <ul style={{ listStyleType: "circle" }}>
                <li>
                  the categories of California Personal Information that we have
                  collected about you;
                </li>
                <li>
                  the categories of California Personal Information about you
                  that we have sold within the meaning of the California
                  Consumer Privacy Act and the categories of third parties to
                  whom the California Personal Information was sold, by category
                  or categories of personal information for each third party to
                  whom the California personal information was sold; and
                </li>
                <li>
                  the categories of California Personal Information about you
                  that we disclosed for a business purpose.
                </li>
              </ul>
            </li>
            <li>
              Right to Opt Out. In addition, we may sell your information within
              the meaning of the California Consumer Privacy Act. You have the
              right to opt out of such sales here. To submit an opt-out request
              info@somisomi.com
            </li>
          </ul>
          <br />
          Exercising Access, Data Portability & Deletion Rights
          <br />
          <br />
          To exercise the access, data portability, and deletion rights
          described above, please submit a verifiable consumer request to us by
          either:
          <br />
          <ul className="list-disc pl-10">
            <li>
              By completing our rights request form available info@somisomi.com
            </li>
            <li>Calling us at (213) 435-7188</li>
          </ul>
          <br />
          Only you or a person registered with the California Secretary of State
          that you authorize to act on your behalf, may make a verifiable
          consumer request related to your personal information. You may also
          make a verifiable consumer request on behalf of your minor child.
          <br />
          <br />
          You may only make a verifiable consumer request for access or data
          portability twice within a 12-month period. The verifiable consumer
          request must:
          <ul className="list-disc pl-10">
            <li>
              Provide sufficient information that allows us to reasonably verify
              you are the person about whom we collected personal information or
              an authorized representative.
            </li>
            <li>
              Describe your request with sufficient detail that allows us to
              properly understand, evaluate, and respond to it.
            </li>
          </ul>
          <br />
          We cannot respond to your request or provide you with personal
          information if we cannot verify your identity or authority to make the
          request and confirm the personal information relates to you. Making a
          verifiable consumer request does not require you to create an account
          with us. We will only use personal information provided in a
          verifiable consumer request to verify the requestor's identity or
          authority to make the request.
          <br />
          <br />
          Nondiscrimination
          <br />
          <br />
          Subject to applicable law, we will not discriminate against you
          because of your exercise of any of the above rights, or any other
          rights under the California Consumer Privacy Act, including by:
          <br />
          <ul className="list-disc pl-10">
            <li>Denying you goods or services;</li>
            <li>
              Charging different prices or rates for goods or services,
              including through the use of discounts or other benefits or
              imposing penalties;
            </li>
            <li>
              Providing you a different level or quality of goods or services;
              or
            </li>
            <li>
              Suggesting that you will receive a different price or rate for
              goods or services or a different level or quality of goods or
              services.
            </li>
          </ul>
          <br />
          Financial Incentive Program
          <br />
          <br />
          From time to time, we may offer financial incentives, including
          payments to you as compensation, for the collection of your personal
          information, the sale of your personal information, or the deletion of
          your personal information. These financial incentives include
          discounts, coupons, gift cards, survey rewards, and financial
          incentives of various forms of payment. To participate, please email
          us at info@somisomi.com. You may revoke your consent to participate in
          our financial incentive program at any time.
          <br />
          <br />
          Do Not Sell My Personal Information
          <br />
          <br />
          If you are a California resident, you have the right to opt out of our
          sales of certain information relating to individuals and households
          subject to the California Consumer Privacy Act. To exercise this
          opt-out right, whether you have an account or not, please submit a
          request via info@somisomi.com. If you do not have an account, include
          your name and email address or mailing address.
        </div>
      </div>
    </>
  );
};

export default CCPA;
