import React, { useState, useEffect } from "react";
import Announcement from "./Announcement";
import { useNavigate, useLocation } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [isMenuDropdownVisible, setIsMenuDropdownVisible] = useState(false);
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
  const [isOpportunitiesDropdownVisible, setIsOpportunitiesDropdownVisible] =
    useState(false);
  const menuCheckObj = {
    "/softserve": true,
    "/taiyaki": true,
    "/somisips": true,
    "/nutrition-facts": true,
    "/allergen-info": true,
  };
  const opportunitiesCheckObj = {
    "/careers": true,
    "/franchise-one": true,
    "/franchise-two": true,
    "/fundmi": true,
  };
  useEffect(() => {
    setIsMenuDropdownVisible(false);
    setIsMobileMenuVisible(false);
    setIsOpportunitiesDropdownVisible(false);
  }, [pathname]);

  return (
    <>
      <Announcement />

      {/* Hidden navbar - smaller screens */}
      <div className="lg:hidden relative flex items-center justify-center mt-8">
        {/* SOMISOMI Logo */}
        <img
          onClick={() => navigate("/")}
          src="/img/logo@2x.png"
          alt="Logo"
          className="w-[10rem] cursor-pointer"
        />

        {/* Hamburger Menu */}
        <button
          onClick={() => setIsMobileMenuVisible(true)}
          id="mobile-menu-button"
          className="p-2 focus:outline-none focus:bg-gray-300 absolute left-5 top-1/2 transform -translate-y-1/2"
        >
          <svg
            className="w-8 h-8"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
              d="M4 5h19.2M4 14h13.44"
            ></path>
          </svg>
        </button>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuVisible && (
        <div className="fixed top-0 left-0 w-full h-full bg-purple-700 flex items-center justify-center z-[999]">
          {/* Close Menu Button */}
          <button
            onClick={() => setIsMobileMenuVisible(false)}
            className="absolute top-5 right-5 focus:outline-none"
          >
            <svg
              className="w-6 h-6 text-white"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>

          {/* Menu options */}
          <div className="flex flex-col font-bilagro text-3xl text-center text-white space-y-4">
            <button
              onClick={() => navigate("/")}
              className={`focus:outline-none focus:bg-purple-800 ${
                pathname === "/" ? "opacity-100" : "opacity-50"
              } px-4 py-2 rounded`}
            >
              HOME
            </button>
            <button
              onClick={() => {
                setIsMenuDropdownVisible(!isMenuDropdownVisible);
              }}
              className={`focus:outline-none focus:bg-purple-800 ${
                menuCheckObj[pathname] ? "opacity-100" : "opacity-50"
              } px-4 py-2 rounded `}
            >
              MENU
            </button>

            {isMenuDropdownVisible && (
              <div className="flex flex-col">
                <button
                  onClick={() => navigate("/softserve")}
                  className={`focus:outline-none focus:bg-purple-800 ${
                    pathname === "/softserve" ? "opacity-100" : "opacity-50"
                  } px-4 py-2 text-lg rounded`}
                >
                  - SOFT SERVES
                </button>
                <button
                  onClick={() => navigate("/taiyaki")}
                  className={`focus:outline-none focus:bg-purple-800 ${
                    pathname === "/taiyaki" ? "opacity-100" : "opacity-50"
                  } px-4 py-2 text-lg rounded`}
                >
                  - TAIYAKI
                </button>
                <button
                  onClick={() => navigate("/somisips")}
                  className={`focus:outline-none focus:bg-purple-800 ${
                    pathname === "/somisips" ? "opacity-100" : "opacity-50"
                  } px-4 py-2 text-lg rounded`}
                >
                  - SOMI SIPS
                </button>
                <button
                  onClick={() => navigate("/nutrition-facts")}
                  className={`focus:outline-none focus:bg-purple-800 ${
                    pathname === "/nutrition-facts"
                      ? "opacity-100"
                      : "opacity-50"
                  } px-4 py-2 text-lg rounded`}
                >
                  - NUTRITION FACTS
                </button>
                <button
                  onClick={() => navigate("/allergen-info")}
                  className={`focus:outline-none focus:bg-purple-800 ${
                    pathname === "/allergen-info" ? "opacity-100" : "opacity-50"
                  } px-4 py-2 text-lg rounded`}
                >
                  - ALLERGEN INFO
                </button>
              </div>
            )}
            <button
              onClick={() => navigate("/locations")}
              className={`focus:outline-none focus:bg-purple-800 ${
                pathname === "/locations" ? "opacity-100" : "opacity-50"
              } px-4 py-2 rounded`}
            >
              LOCATIONS
            </button>
            <button
              onClick={() => navigate("/shop")}
              className={`focus:outline-none focus:bg-purple-800 ${
                pathname === "/shop" ? "opacity-100" : "opacity-50"
              } px-4 py-2 rounded`}
            >
              SHOP
            </button>
            <button
              onClick={() =>
                setIsOpportunitiesDropdownVisible(
                  !isOpportunitiesDropdownVisible
                )
              }
              className={`focus:outline-none focus:bg-purple-800 ${
                opportunitiesCheckObj[pathname] ? "opacity-100" : "opacity-50"
              } px-4 py-2 rounded`}
            >
              OPPORTUNITIES
            </button>

            {isOpportunitiesDropdownVisible && (
              <div className="flex flex-col">
                <button
                  onClick={() => navigate("/careers")}
                  className={`focus:outline-none focus:bg-purple-800 ${
                    pathname === "/careers" ? "opacity-100" : "opacity-50"
                  } px-4 py-2 text-lg rounded`}
                >
                  - CAREER
                </button>
                <button
                  onClick={() => navigate("/franchise-one")}
                  className={`focus:outline-none focus:bg-purple-800 ${
                    pathname === "/franchise-one" ||
                    pathname === "/franchise-two"
                      ? "opacity-100"
                      : "opacity-50"
                  } px-4 py-2 text-lg rounded`}
                >
                  - FRANCHISING
                </button>
                <button
                  onClick={() => navigate("/fundmi")}
                  className={`focus:outline-none focus:bg-purple-800 ${
                    pathname === "/fundmi" ? "opacity-100" : "opacity-50"
                  } px-4 py-2 text-lg rounded`}
                >
                  - FUNDRAISING
                </button>
              </div>
            )}

            <button
              onClick={() => navigate("/story")}
              className={`focus:outline-none focus:bg-purple-800 ${
                pathname === "/story" ? "opacity-100" : "opacity-50"
              } px-4 py-2 rounded`}
            >
              OUR STORY
            </button>
          </div>
        </div>
      )}

      {/* Full navbar - larger screens */}
      <div className="hidden lg:flex flex-row items-center justify-center font-montserrat">
        {/* SOMISOMI Logo */}
        <img
          onClick={() => navigate("/")}
          className="absolute w-[12rem] left-5 ml-24 cursor-pointer"
          src="/img/logo@2x.png"
          alt="logo"
        />

        {/* Links */}
        <div className="flex flex-row items-center justify-center font-montserrat">
          <div
            onClick={() => navigate("/")}
            className="text-grey_1 mx-5 my-10 hover:text-purple_1 cursor-pointer"
          >
            Home
          </div>

          {/* Menu Dropdown */}
          <div className="relative inline-block text-left">
            <div>
              <button
                type="button"
                className="inline-flex justify-center items-center px-4 py-2 bg-white text-grey_1 hover:text-purple_1 focus:outline-none"
                aria-haspopup="true"
                aria-expanded="true"
                onClick={() => setIsMenuDropdownVisible(!isMenuDropdownVisible)}
              >
                Menu
                <svg
                  className="-mr-1 ml-2 h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>

            {isMenuDropdownVisible && (
              <div className="origin-top-left absolute left-0 mt-2 w-44 rounded-md bg-white border border-2 border-purple_2">
                <div
                  className="py-1"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="options-menu"
                  onMouseLeave={() => {
                    setIsMenuDropdownVisible(false);
                  }}
                >
                  <div
                    onClick={() => navigate("/softserve")}
                    className="block px-4 py-2 text-sm text-slate-400 hover:text-purple_1 cursor-pointer"
                    role="menuitem"
                  >
                    Soft Serves
                  </div>
                  <div
                    onClick={() => navigate("/taiyaki")}
                    className="block px-4 py-2 text-sm text-slate-400 hover:text-purple_1 cursor-pointer"
                    role="menuitem"
                  >
                    Taiyaki
                  </div>
                  <div
                    onClick={() => navigate("/somisips")}
                    className="block px-4 py-2 text-sm text-slate-400 hover:text-purple_1 cursor-pointer"
                    role="menuitem"
                  >
                    Somi Sips
                  </div>
                  <div
                    onClick={() => navigate("/nutrition-facts")}
                    className="block px-4 py-2 text-sm text-slate-400 hover:text-purple_1 cursor-pointer"
                    role="menuitem"
                  >
                    Nutrition Facts
                  </div>
                  <div
                    onClick={() => navigate("/allergen-info")}
                    className="block px-4 py-2 text-sm text-slate-400 hover:text-purple_1 cursor-pointer"
                    role="menuitem"
                  >
                    Allergen Info
                  </div>
                </div>
              </div>
            )}
          </div>

          <div
            onClick={() => navigate("/locations")}
            className="text-grey_1 mx-5 my-10 hover:text-purple_1 cursor-pointer"
          >
            Locations
          </div>

          <div
            onClick={() => navigate("/shop")}
            className="text-grey_1 mx-5 my-10 hover:text-purple_1 cursor-pointer"
          >
            Shop
          </div>

          {/* <div
            onClick={() => navigate("/careers")}
            className="text-grey_1 mx-5 my-10 hover:text-purple_1 cursor-pointer"
          >
            Opportunities
          </div> */}

          {/* Menu Dropdown */}
          <div className="relative inline-block text-left">
            <div>
              <button
                type="button"
                className="inline-flex justify-center items-center px-4 py-2 bg-white text-grey_1 hover:text-purple_1 focus:outline-none"
                aria-haspopup="true"
                aria-expanded="true"
                onClick={() =>
                  setIsOpportunitiesDropdownVisible(
                    !isOpportunitiesDropdownVisible
                  )
                }
              >
                Opportunities
                <svg
                  className="-mr-1 ml-2 h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>

            {isOpportunitiesDropdownVisible && (
              <div className="origin-top-left absolute left-0 mt-2 w-44 rounded-md bg-white border border-2 border-purple_2">
                <div
                  className="py-1"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="options-menu"
                  onMouseLeave={() => {
                    setIsOpportunitiesDropdownVisible(false);
                  }}
                >
                  <div
                    onClick={() => navigate("/careers")}
                    className="block px-4 py-2 text-sm text-slate-400 hover:text-purple_1 cursor-pointer"
                  >
                    Career
                  </div>
                  <div
                    onClick={() => navigate("/franchise-one")}
                    className="block px-4 py-2 text-sm text-slate-400 hover:text-purple_1 cursor-pointer"
                  >
                    Franchising
                  </div>
                  <div
                    onClick={() => navigate("/fundmi")}
                    className="block px-4 py-2 text-sm text-slate-400 hover:text-purple_1 cursor-pointer"
                  >
                    Fundraising
                  </div>
                </div>
              </div>
            )}
          </div>

          <div
            onClick={() => navigate("/story")}
            className="text-grey_1 mx-5 my-10 hover:text-purple_1 cursor-pointer"
          >
            Our Story
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
