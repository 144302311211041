import React, { useState, useEffect } from "react";
import Title from "../components/Title";
import StickyMenuNav from "../components/StickyMenuNav";
import MenuProductImage from "../components/MenuProductImage";
import MenuOptions from "../components/MenuOptions";
import LocationDropdown from "../components/LocationDropdown";
import NutritionFacts from "../components/NutritionFacts";
import { rtdb } from "../firebase";
import { ref, get, child } from "firebase/database";

const SomiSipsMenu = () => {
  const [selectedType, setSelectedType] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedFlavor, setSelectedFlavor] = useState(null);
  const [flavorsData, setFlavorsData] = useState(null);
  const [flavors, setFlavors] = useState([])

  const getFlavorsData = async () => {
    const dbRef = ref(rtdb);

    get(child(dbRef, "somisomi/flavors"))
      .then((snapshot) => {
        if (snapshot.exists()) {
          setFlavorsData(snapshot.val());
        } else {
          console.log("Flavors data not available.");
        }
      })
      .catch((error) => {
        console.log("Flavors data not available.");
      });
  };

  useEffect(() => {
    getFlavorsData();
  }, []);

  useEffect(() => {
    if (selectedLocation !== null) {
      const { flavors } = flavorsData[selectedLocation.replace(/ /g, "").toLowerCase()]
      const { list } = flavors

      setFlavors(list)
    }
  }, [flavorsData, selectedLocation])

  useEffect(() => {
    if (selectedType === "Cold Brew" || selectedType === "Strawberry Milk") {
      setSelectedLocation(null);
      setSelectedFlavor(null);
    }
  }, [selectedType]);

  const handleSoftServeSelect = (type) => {
    if (selectedType && selectedType === type) {
      setSelectedType(null);
    } else {
      setSelectedType(type);
    }
  };

  const handleLocationSelect = (location) => {
    setSelectedFlavor(null);
    setSelectedLocation(location);
  };

  const handleFlavorSelect = (flavor) => {
    if (selectedFlavor && selectedFlavor === flavor) {
      setSelectedFlavor(null);
    } else {
      setSelectedFlavor(flavor);
    }
  };

  const types = ["Cold Brew", "Strawberry Milk", "Cold Brew Float"];

  return (
    <>
      <Title text="SOMI SIPS" isMenu={true} />
      <div className="w-full flex md:flex-row flex-col font-montserrat">
        <StickyMenuNav />
        <MenuProductImage
          category="somisips"
          type={selectedType}
          flavor={selectedFlavor}
        />

        {/* Menu */}
        <div className="lg:w-1/2 md:w-1/2 w-full flex flex-col items-center lg:-translate-x-10 md:-translate-x-10">
          <MenuOptions
            heading="CHOOSE A TYPE"
            items={types}
            onSelect={handleSoftServeSelect}
            selectedItems={selectedType}
          />

          {flavorsData ? (
            <>
              {selectedType === "Cold Brew Float" && (
                <LocationDropdown
                  selectedLocation={selectedLocation}
                  onSelect={handleLocationSelect}
                />
              )}
            </>
          ) : (
            <div className="flex flex-col md:w-2/3 w-11/12 mb-14 border-b-2 border-purple_2 pb-5">
              <h1 className="text-xl text-grey_3 font-bilagro mb-10">
                LOADING LOCATIONS...
              </h1>
            </div>
          )}

          {selectedLocation && (
            <MenuOptions
              heading="CHOOSE A FLAVOR"
              items={flavors}
              onSelect={handleFlavorSelect}
              selectedItems={selectedFlavor}
            />
          )}

          <NutritionFacts
            category="somisips"
            type={selectedType}
            flavor={selectedFlavor}
            toppings={[]}
          />
        </div>
      </div>
    </>
  );
};

export default SomiSipsMenu;
