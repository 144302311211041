import React, { useState } from "react";
import Title from "../components/Title";
import StickyMenuNav from "../components/StickyMenuNav";
import MenuProductImage from "../components/MenuProductImage";
import MenuOptions from "../components/MenuOptions";
import NutritionFacts from "../components/NutritionFacts";

import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";

const TaiyakiMenu = () => {
  const [selectedServingOption, setSelectedServingOption] = useState(null);
  const [selectedFilling, setSelectedFilling] = useState(null);

  const handleServingOptionSelect = (type) => {
    if (selectedServingOption && selectedServingOption === type) {
      setSelectedServingOption(null);
    } else {
      setSelectedServingOption(type);
    }
  };

  const handleFillingSelect = (filling) => {
    if (selectedFilling && selectedFilling === filling) {
      setSelectedFilling(null);
    } else {
      setSelectedFilling(filling);
    }
  };

  const serving_options = ["Taiyaki 2PC", "Taiyaki 5PC"];
  const fillings = [
    "Nutella",
    "Oreo",
    "Cheddar",
    "Custard",
    "Red Bean",
    // "Hotteok",
    //additions
    // "Mint Oreo",
    "Black Sesame",
    "Matcha Custard",
  ];

  return (
    <>
      <Title text="TAIYAKI" isMenu={true} />
      <div className="w-full flex md:flex-row flex-col font-montserrat">
        <StickyMenuNav />
        <MenuProductImage category="taiyaki" type={selectedServingOption} filling={selectedFilling} />

        {/* Menu */}
        <div className="lg:w-1/2 md:w-1/2 w-full flex flex-col items-center lg:-translate-x-10 md:-translate-x-10">
          <MenuOptions
            heading="SELECT SERVING OPTION"
            items={serving_options}
            onSelect={handleServingOptionSelect}
            selectedItems={selectedServingOption}
          />

          <MenuOptions
            heading="CHOOSE A FILLING"
            items={fillings}
            onSelect={handleFillingSelect}
            selectedItems={selectedFilling}
            subHeading="&nbsp;&nbsp; *can order mix and match"
          />

          <NutritionFacts category="taiyaki" type={selectedServingOption} filling={selectedFilling} toppings={[]} />
        </div>
      </div>
      <Title text="Reheating Instructions" isMenu={false} />
      <div className="w-full flex flex-col items-center my-4">
        <div className="aspect-w-16 aspect-h-9 w-3/4 max-w-md mx-auto">
          <LiteYouTubeEmbed id="MS1BWLB8KXQ" title="Video: How to Reheat your Somisomi Taiyaki" />
        </div>
      </div>
    </>
  );
};

export default TaiyakiMenu;
