import React from "react";
import Title from "../components/Title";

const InfoTemplate = ({ title, images }) => {
  return (
    <>
      <Title text={title} />
      <div className="flex flex-col items-center justify-center">
        {images.map((src, index) => (
          <img
            key={index}
            className="md:w-2/3 w-4/5 mb-10"
            src={src}
            alt={"page " + index}
          />
        ))}
      </div>
    </>
  );
};

export default InfoTemplate;
