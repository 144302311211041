import React from "react";
import Title from "../components/Title";

const ADA = () => {
  return (
    <>
      <Title text="ADA COMPLIANCE" />

      <div className="w-full flex items-center justify-center font-montserrat text-grey_1 mb-20">
        <div className="w-2/3 lg:text-base text-sm">
          ADDITIONAL INFORMATION FOR CALIFORNIA CONSUMERS <br /> Under the
          California Consumer Privacy Act (“CCPA”), we are required to provide
          certain information to California consumers about how we handle their
          personal information that we have collected, whether online or
          offline. For purposes of this section of our privacy policy, and per
          the CCPA, “personal information” is broadly defined to include any
          information that identifies, relates to, describes, is capable of
          being associated with, or could reasonably be linked, directly or
          indirectly, with a particular consumer or household, including the
          categories identified in the table below to the extent they identify,
          relate to, describe, are capable of being associated with, or could be
          reasonably linked, directly or indirectly, with a particular consumer
          or household. <br /> Categories of Personal Information that We
          Collect, Disclose, and Sell The table below discloses the categories
          of personal information, as defined by CCPA, about California
          consumers that we collect, sell, and disclose for a business purpose.
          Since this section also includes offline data, this section may be
          broader than the disclosures set forth above. Category <br />
          <br /> A. Identifiers. EXAMPLE: A real name, alias, postal address,
          unique personal identifier, online identifier, Internet Protocol
          address, email address, account name, Social Security number, driver's
          license number, passport number, or other similar identifiers.
          COLLECTED?: YES <br />
          <br /> B. Personal information categories listed in the California
          Customer Records statute (Cal. Civ. Code § 1798.80(e)). EXAMPLE: A
          name, signature, Social Security number, physical characteristics or
          description, address, telephone number, passport number, driver's
          license or state identification card number, insurance policy number,
          education, employment, employment history, bank account number, credit
          card number, debit card number, or any other financial information,
          medical information, or health insurance information. Some personal
          information included in this category may overlap with other
          categories. COLLECTED?: YES
        </div>
      </div>
    </>
  );
};

export default ADA;
