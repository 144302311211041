import React, { useRef, useState, useEffect } from "react";
import Title from "../components/Title";
import Iframe from "react-iframe";
import IframeResizer from "iframe-resizer-react";

const FranchiseInquiryForm = () => {
  const ref = useRef();
  const [height, setHeight] = useState("0px");

  useEffect(() => {
    console.log("ref", ref.current);
    //setHeight(ref.current.clientHeight);
  });

  return (
    <>
      <Title text="Franchise Application" />
      {/* <Iframe
        url="https://form.123formbuilder.com/6413351/franchise-inquiry-form-new"
        width="100%"
        height="3100px"
        id=""
        className=""
        display="block"
        position="relative"
        overflow="auto"
      /> */}
      {/* <iframe
        ref={ref}
        onLoad={onLoad}
        allowTransparency="true"
        width="100%"
        height={height}
        id="contactform123"
        name="contactform123"
        marginwidth="0"
        marginheight="0"
        frameborder="0"
        src="https://form.123formbuilder.com/my-contact-form-6413351.html?customVars123=yes&hasEmbedFormStyle=1"
      >
        <p>
          Your browser does not support iframes. The contact form cannot be
          displayed. Please use another contact method (phone, fax etc)
        </p>
      </iframe> */}

      <IframeResizer
        forwardRef={ref}
        style={{
          minWidth: "100%",
          border: "none",
          minHeight: "100%",
        }}
        src="https://form.123formbuilder.com/my-contact-form-6734592.html?customVars123=yes&hasEmbedFormStyle=1"
        heightCalculationMethod="max"
        scrolling="omit"
        checkOrigin={false} // Example additional option
      />
    </>
  );
};

export default FranchiseInquiryForm;
