
import React from "react";
import Title from "../components/Title";

const STORE_SURVEY_FORM_LINK = 'https://form.123formbuilder.com/6645123/somi-customer-lab-store-survey'
const CUSTOMER_IDEA_SUBMISSION_LINK = 'https://form.123formbuilder.com/6645239/somi-lab-customer-ideas'

const LabSurvey = () => {
    return (
        <>
            <Title text="CUSTOMER SURVEY" />

            <div className="flex items-center md:flex-row flex-col-reverse justify-between gap-y-6 gap-x-10 mb-20 lg:mx-40 md:mx-10">
                <div className="flex flex-col md:items-start items-center lg:gap-y-12 gap-y-6">
                    <div className="bg-dark-yellow lg:px-4 md:px-2 px-1 md:py-4 py-1 cursor-pointer" onClick={() => window.location.replace(STORE_SURVEY_FORM_LINK)}>
                        <span className="flex md:text-2xl text-xl font-bilagro text-center text-white">
                            Lab Store
                        </span>
                    </div>
                    <div className="bg-dark-yellow lg:px-4 md:px-2 px-1 md:py-4 py-1 cursor-pointer" onClick={() => window.location.replace(CUSTOMER_IDEA_SUBMISSION_LINK)}>
                        <span className="flex md:text-2xl text-xl font-bilagro text-center text-white">
                            New Idea Submission
                        </span>
                    </div>
                </div>
                <img className="overflow-auto w-1/2" src={'/img/lab-survey/lab-survey.png'} alt="lab-survey" />
            </div>
        </>
    )
}

export default LabSurvey;
