import React, { useEffect, useState } from "react";
import Carousel from "../components/Carousel";
import Slideshow from "../components/Slideshow";
import { useNavigate } from "react-router-dom";

import { storage, rtdb } from "../firebase";
import { ref, listAll, getDownloadURL } from "firebase/storage";

import { ref as refdb, get, child } from "firebase/database";

const Home = () => {
  const [newsImages, setNewsImages] = useState([]);
  const [newsLive, setNewsLive] = useState(false);

  const navigate = useNavigate();

  const getNewsLive = async () => {
    const dbRef = refdb(rtdb);
    get(child(dbRef, "somisomi/news-live"))
      .then((snapshot) => {
        if (snapshot.exists()) {
          setNewsLive(snapshot.val());
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchNewsImages = async (uploading = false) => {
    const storageRef = ref(storage, "news-images/");
    const result = await listAll(storageRef);

    const urlPromises = result.items.map((imageRef) => getDownloadURL(imageRef));

    const downloadURLs = await Promise.all(urlPromises);
    const validURLs = downloadURLs.filter((url) => url); // filter out null or failed URLs

    if (uploading) {
      return validURLs;
    }

    let temp = [];
    validURLs.map((url) => {
      temp.push({ src: url, alt: "" });
    });

    setNewsImages(temp);
  };

  const sliderImages = [
    {
      src: "/img/homepage/ahboong-slider/Matcha-Ube-AB.png",
      alt: "Matcha Ube AB.png",
    },
    {
      src: "/img/homepage/ahboong-slider/Ahboong_Strawberry.png",
      alt: "Ahboong Strawberry",
    },
    {
      src: "/img/homepage/ahboong-slider/Pink-Guava.png",
      alt: "Pink Guava",
    },
    {
      src: "/img/homepage/ahboong-slider/Ahboong_Milktea.png",
      alt: "Ahboong Milk tea",
    },
    {
      src: "/img/homepage/ahboong-slider/Ahboong-NewCone_CnC.png",
      alt: "Ahboong NewCone",
    },
    {
      src: "/img/homepage/ahboong-slider/Ahboong_Horchata.png",
      alt: "Ahboong Horchata",
    },

    {
      src: "/img/homepage/ahboong-slider/Milk-with-Sprinkles.png",
      alt: "Milk with Sprinkles",
    },
  ];

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const newsSliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  const slideShowImages = [
    {
      src: "/img/homepage/somisips-slideshow/strawberry-milk.png",
      alt: "Strawberry Milk"
    },
    {
      src: "/img/homepage/somisips-slideshow/cold-brew-bottle.png",
      alt: "Cold Brew Bottle"
    },
    {
      src: "/img/homepage/somisips-slideshow/cold-brew-float.png",
      alt: "Cold Brew Float"
    }
  ]

  useEffect(() => {
    getNewsLive();
    fetchNewsImages();
  }, []);

  return (
    <>
      {/* NOT TOO SWEET */}
      <section className="bg-grad">
        <div className="flex flex-col items-center justify-center space-y-6 md:space-y-8 lg:space-y-12">
          <div className="w-full mt-16 flex flex-col items-center justify-center">
            <h1 className="md:text-6xl text-3xl font-bilagro font-bold text-purple_1">NOT TOO SWEET</h1>
            <p className="lg:w-1/3 md:w-3/5 w-[82.66%] mt-3 font-montserrat text-base text-grey_1 text-center">
              Our signature goldfish-shaped waffle cone stuffed with your choice of filling & soft serve.
            </p>
            <button
              onClick={() => navigate("/softserve")}
              className="w-40 px-4 py-3 mt-4 bg-purple_1 font-montserrat-semibold text-white text-sm rounded-full"
            >
              VIEW MENU
            </button>
          </div>
        </div>
        <Carousel sliderImages={sliderImages} settings={sliderSettings} isNews={false} />
      </section>

      {/* NEWS CAROUSEL */}
      {newsLive && <Carousel sliderImages={newsImages} settings={newsSliderSettings} isNews={true} />}
      {/* TAIYAKI */}
      <section className="transform -translate-y-[2.5rem] lg:bg-[url('../public/img/gradient-background-2.png')] md:bg-[url('../public/img/gradient-background-2.png')] bg-[url('../public/img/gradient-background-2-small.png')] bg-center bg-cover">
        <div className="relative w-full flex lg:flex-row md:flex-row flex-col">
          <div className="lg:w-1/2 md:w-1/2 w-full flex items-center justify-center lg:p-0 md:p-15 pt-20 px-20 pb-6">
            <div className="lg:ml-40 lg:block flex flex-col justify-center items-center">
              <h1 className="md:text-6xl text-4xl font-bilagro font-bold text-purple_1 md:mb-10 mb-5">TAIYAKI</h1>
              <p className="md:w-3/4 w-full mt-3 text-lg text-grey_1 mb-5 lg:text-left text-center">
                Crispy and chewy fish-shaped pastry with your choice of filling.
              </p>
              <button
                onClick={() => navigate("/taiyaki")}
                className="w-40 px-4 py-3 mt-4 bg-purple_1 font-montserrat-semibold text-sm text-white rounded-full"
              >
                VIEW MENU
              </button>
            </div>
          </div>

          {/* TAIYAKI IMAGE */}
          <div className="md:w-1/2 w-full md:pt-32 flex justify-end">
            <img className="md:w-full w-[90%]" src="/img/homepage/handheld-taiyaki.png" alt="Taiyaki image" />
          </div>
        </div>
      </section>

      {/* SOMI SIPS */}
      <section className="transform -translate-y-[2.5rem] bg-grad">
        <div className="relative w-full flex lg:flex-row md:flex-row flex-col">
          <div className="lg:w-1/2 lg:pr-32 md:w-1/2 w-full flex items-center justify-center lg:p-0 md:pr-14 pr-20 pt-16 lg:order-2 md:order-2 order-1 text-center">
            <div className="lg:block flex flex-col justify-center items-center">
              <h1 className="md:text-6xl text-4xl font-bilagro font-bold text-purple_1 md:mb-10 mb-5">
                SOMI SIPS
              </h1>
              <div className="mt-3 mb-5 text-grey_1 text-center md:text-lg sm:text-base text-sm">
                <p>Thirst quenching? Perhaps.</p>
                <p>Flowing with flavors that will blow your mind?</p>
                <p>You've got it.</p>
              </div>
              <button
                onClick={() => navigate("/somisips")}
                className="w-40 px-4 py-3 mt-4 bg-purple_1 font-montserrat-semibold text-sm text-white rounded-full"
              >
                VIEW MENU
              </button>
            </div>
          </div>

          <Slideshow images={slideShowImages} background={false} size="h-[27rem]"/>
        </div>
      </section>

      {/* STORE / NUTRITION */}
      <section className="flex lg:flex-row md:flex-row flex-col sm:pb-32 md:pb-10 h-[32rem] sm:justify-between transform -translate-y-[2.5rem] bg-grad">
        <div className="lg:pl-32 md:w-1/2 lg:mt-0 md:mt-10 mt-12 w-full flex flex-col items-center justify-center">
          <h1 className="md:text-6xl text-4xl font-bilagro text-purple_1 md:w-96 w-2/3 text-center mb-5">
            FIND A STORE
          </h1>
          <button
            onClick={() => navigate("/locations")}
            className="w-40 px-4 py-3 mt-4 bg-purple_1 font-montserrat-semibold text-sm text-white rounded-full"
          >
            VISIT PAGE
          </button>
        </div>
        <div className="lg:pr-32 md:pr-14 md:w-1/2 lg:mt-0 md:mt-10 mt-24 w-full flex flex-col items-center justify-center">
          <h1 className="md:text-6xl text-4xl font-bilagro text-purple_1 md:w-96 w-2/3 text-center mb-5">
            NUTRITION FACTS
          </h1>
          <button
            onClick={() => navigate("/nutrition-facts")}
            className="w-40 px-4 py-3 mt-4 bg-purple_1 font-montserrat-semibold text-sm text-white rounded-full"
          >
            VISIT PAGE
          </button>
        </div>
      </section>
    </>
  );
};

export default Home;
