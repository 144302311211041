import React from "react";
import Title from "../components/Title";
import FaqComponent from "../components/FaqComponent";
import { useNavigate } from "react-router-dom";

const Faq = () => {
  const navigate = useNavigate();

  const faqs = [
    {
      q: "Where can I find information about franchise opportunities?",
      a: (
        <p
          className="cursor-pointer"
          onClick={() => navigate("/franchise-one")}
        >
          Click me to view franchise opportunities!
        </p>
      ),
    },
    {
      q: "Where can I find information about career opportunities?",
      a: (
        <p className="cursor-pointer" onClick={() => navigate("/careers")}>
          Click me to view career opportunities!
        </p>
      ),
    },
    {
      q: "Where can I find information about nutrition information?",
      a: (
        <p
          className="cursor-pointer"
          onClick={() => navigate("/nutrition-facts")}
        >
          Click me to view nutrition information!
        </p>
      ),
    },
    {
      q: "Where can I find information about allergen information?",
      a: (
        <p
          className="cursor-pointer"
          onClick={() => navigate("/allergen-info")}
        >
          Click me to view allergen information!
        </p>
      ),
    },
    {
      q: "Where can I find information about menu information?",
      a: (
        <p className="cursor-pointer" onClick={() => navigate("/softserve")}>
          Click me to view menu information!
        </p>
      ),
    },
  ];
  return (
    <>
      <Title text="FAQ" />
      <FaqComponent faqs={faqs} />
    </>
  );
};

export default Faq;
