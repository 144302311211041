import React from "react";
import { useNavigate } from "react-router-dom";

const JobCard = ({ title, iconUrl, link }) => {
  const navigate = useNavigate();
  const isLinkNavigation = link[0] === "/";
  return (
    <div className="flex flex-col bg-purple_2 md:w-[30%] md:items-start items-center w-3/4 mb-10 p-5 rounded-lg">
      <img className="w-14 mb-5" src={iconUrl} alt="" />
      <h1 className="font-bilagro text-purple_1 md:text-left text-center text-2xl mb-5">
        {title}
      </h1>
      <button
        onClick={() => {
          if (isLinkNavigation) {
            navigate(link);
          } else {
            window.open(link, "_blank");
          }
        }}
        className="w-32 px-4 py-3 bg-purple_1 font-montserrat-semibold text-xs text-white rounded-full"
      >
        APPLY NOW
      </button>
    </div>
  );
};

export default JobCard;
