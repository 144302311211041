import React from "react";
import Title from "../components/Title";
import { useNavigate } from "react-router-dom";

const CustomerService = () => {
  const navigate = useNavigate();

  return (
    <>
      <Title text="CUSTOMER SERVICE" />

      <div className="flex justify-center w-full mb-20 font-montserrat-semibold text-black">
        <div className="flex lg:flex-row flex-col justify-center w-[80%] gap-x-10">
          <div className="flex flex-col bg-purple_2 md:h-full items-center w-full lg:mb-0 mb-10 p-5 rounded-lg">
            <img className="w-8 mb-5 mt-5" src="/img/bag.png" alt="" />
            <h1 className="font-bilagro text-purple_1 text-center text-2xl mb-10">
              OUR PRODUCTS
            </h1>
            <span
              onClick={() => navigate("/softserve")}
              className="mb-5 text-center cursor-pointer"
            >
              Menu
            </span>
            <span
              onClick={() => navigate("/nutrition-facts")}
              className="mb-5 text-center cursor-pointer"
            >
              Nutrition
            </span>
            <a
              href="https://squareup.com/gift/VPWTW4B3FABB9/order"
              className="mb-5 text-center cursor-pointer"
            >
              E-Gift Card
            </a>
          </div>

          <div className="flex flex-col bg-purple_2 md:h-full items-center w-full p-5 rounded-lg">
            <img className="w-8 mb-5 mt-5" src="/img/bag.png" alt="" />
            <h1 className="font-bilagro text-purple_1 text-center text-2xl mb-10">
              MORE QUESTIONS?
            </h1>
            <span
              onClick={() => navigate("/faq")}
              className="mb-5 text-center cursor-pointer"
            >
              FAQ
            </span>
            <span
              onClick={() => navigate("/franchise-one")}
              className="mb-5 text-center cursor-pointer"
            >
              Franchising Opportunity
            </span>
            <span
              onClick={() => navigate("/fundmi")}
              className="mb-5 text-center cursor-pointer"
            >
              Fundraising
            </span>
            <span
              onClick={() => navigate("/careers")}
              className="mb-5 text-center cursor-pointer"
            >
              Join Our Team
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerService;
