import React from "react";
import Title from "../components/Title";
import JobCard from "../components/JobCard";

const Careers = () => {
  const jobs = [
    {
      title: "Store Member",
      iconUrl: "/img/career-icon.png",
      link: "https://forms.monday.com/forms/e0d19505b0ffeebff952a708a64d4280?r=use1",
    },
  ];

  return (
    <>
      <Title text="CAREERS" />

      <div className="w-full flex justify-center mb-20">
        <video className="w-2/3 rounded-lg" controls autoPlay muted>
          <source
            src="https://firebasestorage.googleapis.com/v0/b/somisomi-8df7f.appspot.com/o/videos%2Fcareer.mp4?alt=media&token=942a4e3f-9241-4eec-b341-ff628996d2f4"
            type="video/mp4"
          />
        </video>
      </div>

      <div className="flex justify-center w-full">
        <h1 className="w-[80%] text-center font-bilagro lg:text-6xl md:text-6xl text-5xl text-purple_1 mb-20">
          AVAILABLE POSITIONS
        </h1>
      </div>

      <div className="flex justify-center w-full mb-20">
        <div className="flex flex-wrap md:flex-row flex-col space-evenly gap-x-10 items-center w-[80%]">
          {jobs.map((job, index) => (
            <JobCard
              key={index}
              title={job.title}
              iconUrl={job.iconUrl}
              link={job.link}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Careers;
