import React, { useState, useEffect } from "react";
import { rtdb } from "../firebase";
import { ref, get, child } from "firebase/database";

const Announcement = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [announcementLive, setAnnouncementLive] = useState(false);
  const [announcementText, setAnnouncementText] = useState([]);
  const intervalSpeed = 2700;
  const [currentIndex, setCurrentIndex] = useState(0);

  const getAnnouncementLive = async () => {
    const dbRef = ref(rtdb);
    get(child(dbRef, "somisomi/announcement/live"))
      .then((snapshot) => {
        if (snapshot.exists()) {
          setAnnouncementLive(snapshot.val());
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchAnnouncementText = async () => {
    const dbRef = ref(rtdb);
    get(child(dbRef, "somisomi/announcement/text"))
      .then((snapshot) => {
        if (snapshot.exists()) {
          setAnnouncementText(snapshot.val().split("/br"));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleCloseAnnouncement = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    getAnnouncementLive();
    fetchAnnouncementText();
  }, []);

  useEffect(() => {
    if (announcementLive) {
      setIsVisible(true);
    }
  }, [announcementLive]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % announcementText.length);
    }, intervalSpeed);

    return () => {
      clearInterval(interval);
    };
  }, [announcementText.length]);

  if (!isVisible) {
    return null;
  }

  const sliderContainerStyle = {
    height: "25px",
    overflow: "hidden",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  };

  const sliderItemStyle = (isActive, isPrevActive) => ({
    position: "absolute",
    width: "100%",
    opacity: isActive ? 1 : 0,
    transform: isActive ? "translateY(0%)" : isPrevActive ? "translateY(-100%)" : "translateY(100%)",
    transition: "transform 0.8s ease, opacity 0.8s ease",
    backgroundColor: "transparent",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  });

  return (
    <div className="relative flex items-center justify-center p-1 bg-purple_1 text-white font-montserrat lg:block hidden">
      <div className="flex flex-row items-center justify-center" style={sliderContainerStyle}>
        {announcementText.map((text, index) => (
          <span
            className="text-center"
            key={index}
            style={sliderItemStyle(index === currentIndex, (index + 1) % announcementText.length === currentIndex)}
          >
            {text}
          </span>
        ))}
      </div>

      {/* Close button */}
      <span className="absolute right-2 top-1/2 transform -translate-y-1/2 text-xl" onClick={handleCloseAnnouncement}>
        <img className="h-4 cursor-pointer" src="/img/close.svg" alt="close" />
      </span>
    </div>
  );
};

export default Announcement;
