import React, { useState, useEffect } from "react";
import _ from "lodash";
import { rtdb } from "../firebase";
import { ref, get, child } from "firebase/database";

const NutritionFacts = ({
  category = null,
  type = null,
  flavor = null,
  filling = null,
  toppings = [],
}) => {
  const flavors = _.isNull(flavor) ? [] : flavor.flavor

  const initialNutritionState = () => ({
    calories: {
      unit: "kcal",
      value: 0,
    },
    total_carbs: {
      unit: "g",
      value: 0,
    },
    total_fat: {
      unit: "g",
      value: 0,
    },
    saturated_fat: {
      unit: "g",
      value: 0,
    },
    allergens: [],
    sodium: {
      unit: "mg",
      value: 0,
    },
    serving_size: {
      unit: "g",
      value: 0,
    },
    protein: {
      unit: "g",
      value: 0,
    },
    trans_fat: {
      unit: "g",
      value: 0,
    },
    cholesterol: {
      unit: "mg",
      value: 0,
    },
    sugar: {
      unit: "g",
      value: 0,
    },
    total_added_sugar: {
      unit: "g",
      value: 0,
    },
    dietary_fiber: {
      unit: "g",
      value: 0,
    },
  });
  const [showNutritionFacts, setShowNutritionFacts] = useState(false);
  const [nutritionData, setNutritionData] = useState(null);
  const [facts, setFacts] = useState(initialNutritionState());

  const getNutritionData = async () => {
    const dbRef = ref(rtdb);

    get(child(dbRef, "somisomi/nutrition"))
      .then((snapshot) => {
        if (snapshot.exists()) {
          setNutritionData(snapshot.val());
        } else {
          console.log("Nutrition data not available.");
        }
      })
      .catch((error) => {
        console.log("Nutrition data not available.");
      });
  };

  useEffect(() => {
    getNutritionData();
  }, []);

  // Turns this "serving_size" to "Serving Size"
  const formatKey = (key) => {
    return key
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  // Removes quotation marks and spaces from a string
  const formatValue = (value) =>
    value ? value.replace(/["' ]/g, "").toLowerCase() : "";

  const getFillingData = (category, type) => {
    if (
      category === "softserve" &&
      (type === "Ah Boong" || type === "Upside Down")
    ) {
      return { category: "ahboong_fillings", baseCategory: "ahboong" };
    }
    if (category === "taiyaki" && type) {
      return { category: "taiyaki_fillings", baseCategory: "taiyaki" };
    }
    return null;
  };

  const updateNutrition = (sourceData, targetData, portion = 1, onlyTaiyaki = false) => {
    let multiplier = 1;

    if (onlyTaiyaki) {
      if (type === "Taiyaki 2PC") {
        multiplier = 2;
      } else if (type === "Taiyaki 5PC") {
        multiplier = 5;
      }
    }

    Object.keys(targetData)?.forEach((nutrient) => {
      if (sourceData[nutrient]) {
        targetData[nutrient].value += sourceData[nutrient].value * multiplier * portion;

        if (nutrient === "allergens" && sourceData.allergens) {
          targetData.allergens = [
            ...new Set([...targetData.allergens, ...sourceData.allergens]),
          ];
        }
      }
    });
  };

  useEffect(() => {
    const currentNutritionFacts = initialNutritionState();

    if (nutritionData && !_.isEmpty(flavors)) {
      const portion = 1 / flavors.length

      flavors.forEach(item => {
        if (!_.isEmpty(flavors) && nutritionData.flavors[formatValue(item)]) {
          updateNutrition(nutritionData.flavors[formatValue(item)], currentNutritionFacts, portion);
        }
      })
    }

      const fillingData = getFillingData(category, type);
      if (fillingData) {
        if (nutritionData[fillingData.baseCategory]) {
          if (category === "taiyaki") {
            updateNutrition(
              nutritionData[fillingData.baseCategory],
              currentNutritionFacts,
              true
            );
          } else {
            updateNutrition(nutritionData[fillingData.baseCategory], currentNutritionFacts);
          }
        }
      }

      if (filling && fillingData) {
        updateNutrition(
          nutritionData[fillingData.category][formatValue(filling)],
          currentNutritionFacts
        );
      }

      toppings?.forEach((topping) => {
        if (nutritionData.toppings[formatValue(topping)]) {
          updateNutrition(nutritionData.toppings[formatValue(topping)], currentNutritionFacts);
        }
      });

      if (category === "somisips" && type) {
        updateNutrition(nutritionData.drinks[formatValue(type)], currentNutritionFacts);
      }

    setFacts(currentNutritionFacts);
  }, [category, type, flavor, filling, toppings]);

  return (
    <>
      {showNutritionFacts && (
        <div className="bg-purple_3 flex flex-col md:w-2/3 w-11/12 text-grey_1 rounded-3xl py-8 px-7 mb-12">
          <h1 className="text-xl text-purple_1 font-bilagro mb-5">
            NUTRITION FACTS
          </h1>
          {Object.keys(facts).map((key, index) => {
            const displayKey = formatKey(key);
            const value = facts[key].value;
            const unit = facts[key].unit;

            return (
              <div
                key={index}
                className="flex justify-between items-center mb-2"
              >
                <span className="mr-2 text-grey_1">{displayKey}</span>
                <div className="flex-grow border-b border-dashed mx-2"></div>
                {key === "allergens" ? (
                  <span className="max-w-[55%] text-purple_1 text-right">
                    {facts[key].length === 0
                      ? "None"
                      : facts[key]
                          .map(
                            (str) => str.charAt(0).toUpperCase() + str.slice(1)
                          )
                          .join(", ")}
                  </span>
                ) : (
                  <span className="ml-2 text-purple_1">{`${value} ${unit}`}</span>
                )}
              </div>
            );
          })}
        </div>
      )}
      {nutritionData ? (
        <button
          onClick={() => setShowNutritionFacts(true)}
          className="w-2/3 px-4 py-3 md:mt-0 mt-10 md:mb-20 mb-10 bg-purple_1 font-montserrat-semibold text-sm text-white rounded-full"
        >
          VIEW NUTRITION FACTS
        </button>
      ) : (
        <button className="w-2/3 px-4 py-3 mb-20 bg-purple_1 font-montserrat-semibold text-sm text-white rounded-full">
          Loading...
        </button>
      )}
    </>
  );
};

export default NutritionFacts;
