import React from "react";
import Navbar from "../components/Navbar";
import Title from "../components/Title";
import Footer from "../components/Footer";
import ContentCard from "../components/ContentCard";
import Iframe from "react-iframe";
import { useNavigate } from "react-router-dom";

const FranchiseTwo = () => {
  const navigate = useNavigate();
  const contents = [
    {
      image: "/img/franchise/employee2.png",
      title: {
        text: "Full-Time Dedication",
        size: "lg:text-3xl md:text-2xl text-3xl",
      },
      description:
        "The ideal candidate for franchising Somisomi should be someone who can devote their full time and attention to the store's operations. Owner-operators play a crucial role in maintaining quality standards, managing employees, and ensuring a seamless customer experience. Their commitment to being actively involved in the day-to-day operations is essential for the success of the franchise.",
      order: ["md:order-1 order-2", "md:order-2 order-1"],
      number: "01",
    },
    {
      image: "/img/franchise/employee.jpg",
      title: {
        text: "Passion for the Brand",
        size: "lg:text-3xl md:text-2xl text-3xl",
      },
      description:
        "We seek individuals who are passionate about Somisomi's unique dessert concept and the brand as a whole. A genuine love for the product can drive enthusiasm and dedication, inspiring the franchisee to go above and beyond in delivering exceptional customer service and creating a positive brand experience. <br><br> Strong Leadership Skills: Leadership is vital for a successful franchise. We are looking for candidates with strong leadership qualities, capable of building and motivating a cohesive team. Effective leadership fosters a positive work environment, ensures employees are well-trained, and enables the franchisee to lead by example, setting the standard for excellence in operations. <br><br> By combining these three essential factors - full-time dedication, passion for the brand, and strong leadership skills - we aim to find franchisees who will be instrumental in the growth and success of Somisomi stores while delivering a delightful experience to our valued customers.",
      order: ["order-2", "order-1"],
      number: "02",
    },
  ];

  return (
    <>
      <Title text="WHO ARE WE LOOKING FOR?" />

      <div className="w-full flex flex-col items-center justify-center font-montserrat text-purple_2">
        {contents.map((content, index) => (
          <ContentCard
            key={index}
            images={[{ src: content.image, alt: "WHO ARE WE LOOKING FOR?" }]}
            title={content.title}
            description={content.description}
            order={content.order}
            number={content.number}
          />
        ))}
      </div>
      {/* <Embed url="https://form.123formbuilder.com/6413351/franchise-inquiry-form-new" /> */}
      {/* <iframe
        className="w-full h-full overflow-y-visible"
        frameborder="0"
        scrolling="no"
        title="Franchise Inquiry"
        src="https://form.123formbuilder.com/6413351/franchise-inquiry-form-new"
      ></iframe> */}
      {/* <Iframe
        url="https://form.123formbuilder.com/6413351/franchise-inquiry-form-new"
        width="100%"
        height="3100px"
        id=""
        className=""
        display="block"
        position="relative"
        overflow="auto"
      /> */}
      <div className="bg-grad lg:p-14 md:p-20 p-10 lg:mb-14 mb-0 flex flex-col items-center justify-center">
        <h1 className="lg:text-6xl md:text-6xl text-3xl font-bilagro text-purple_1 text-center">
          JOIN OUR FRANCHISE FAMILY
        </h1>

        <button
          onClick={() => navigate("/franchise-application")}
          className="w-40 px-4 py-3 mt-10 bg-purple_1 font-montserrat-semibold text-white text-center text-sm rounded-full"
        >
          CONTINUE
        </button>
      </div>
    </>
  );
};

export default FranchiseTwo;
