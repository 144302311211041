import React, { useState, useEffect } from "react";
import Title from "../components/Title";
import { useNavigate } from "react-router-dom";

import { storage } from "../firebase";
import { ref, listAll, getDownloadURL } from "firebase/storage";

const Shop = () => {
  const [shopImages, setShopImages] = useState([]);

  const navigate = useNavigate();

  const fetchShopImages = async () => {
    const storageRef = ref(storage, "shop-images/");
    const result = await listAll(storageRef);

    const urlPromises = result.items.map((imageRef) =>
      getDownloadURL(imageRef)
    );

    const downloadURLs = await Promise.all(urlPromises);
    const validURLs = downloadURLs.filter((url) => url); // filter out null or failed URLs

    setShopImages(validURLs);
  };

  useEffect(() => {
    fetchShopImages();
  }, []);

  return (
    <>
      <Title text="SHOP" />

      {/* For large screens */}
      <div className="hidden font-montserrat lg:flex flex-row ml-20 mr-20 mb-32 rounded-[1rem] bg-[url('../public/img/shop-banner.png')] bg-center bg-cover">
        <div className="w-1/2 flex justify-center items-center">
          <div className="pl-20">
            <h1
              style={{ fontSize: "2vw" }}
              className="font-bilagro text-white mb-5"
            >
              Order E-Gift Card Today!
            </h1>
            <p style={{ fontSize: "1.25vw" }} className="text-white w-2/3 mb-5">
              Treat your friends and family with the good stuff. E-Gift Cards
              are delivered via email and can be used in-store or online.
            </p>
            <a
              href="https://squareup.com/gift/VPWTW4B3FABB9/order"
              style={{ fontSize: "1vw", width: "10vw" }}
              className="px-4 py-3 mt-4 bg-white font-montserrat-semibold text-purple_1 rounded-full"
            >
              VISIT PAGE
            </a>
          </div>
        </div>
        <div className="w-1/2">
          <img src="/img/shop-cards.png" alt="card" />
          {/* @replace <img src={shopImages[0]} alt="card" /> */}
        </div>
      </div>

      {/* For small screens */}
      <div className="lg:hidden font-montserrat flex flex-col">
        <div className="mt-24 w-full flex flex-col items-center justify-center">
          <h1 className="text-6xl font-bilagro text-purple_1 w-96 text-center mb-5">
            Order E-Gift Card Today!
          </h1>
          <p className="text-grey_1 w-3/5 mb-5 text-center">
            Treat your friends and family with the good stuff. E-Gift Cards are
            delivered via email and can be used in-store or online.
          </p>
          <a
            href="https://squareup.com/gift/VPWTW4B3FABB9/order"
            className="w-40 px-4 py-3 mt-4 bg-purple_1 font-montserrat-semibold text-center text-sm text-white rounded-full"
          >
            VISIT PAGE
          </a>
        </div>
        <div className="mt-24 w-full h-[22rem] relative">
          <img
            className="w-full h-full object-contain"
            src="/img/shop-cards-small.png"
            alt="card"
          />
          {/* @replace  <img
            className="w-full h-full object-contain"
            src={shopImages[1]}
            alt="card"
          /> */}
        </div>
      </div>
    </>
  );
};

export default Shop;
