import React, { useState, useEffect } from "react";

const Slideshow = ({ images, background, size }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    //   CHANGE SLIDES
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % 3);
    }, 1100);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={`slideshow-container md:w-1/2 w-full ${background === false && "lg:pl-32 lg:pt-20 md:pt-16 pt-12"} flex items-center justify-center lg:order-1 md:order-1 order-2`}>
      {images.map((image, index) => {
        return (
          <img
            key={index}
            className={`slide ${size} ${currentSlide !== index && "hidden"}`}
            src={image.src}
            alt={image.alt}
          />
        )
      })}
    </div>
  );
};

export default Slideshow;
