import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

const StickyMenuNav = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <>
      {/* For larger screens */}
      <div className="hidden lg:flex fixed top-1/2 -translate-y-1/2 lg:left-8 flex-col items-center bg-purple_3 rounded-[4rem] z-[20]">
        <img
          className="my-5 mx-3 w-14 cursor-pointer"
          src={
            pathname === "/softserve"
              ? "/img/sticky-nav/softserve_selected.png"
              : "/img/sticky-nav/softserve.png"
          }
          alt=""
          onClick={() => navigate("/softserve")}
        />
        <img
          className="my-3 mx-3 w-14 cursor-pointer"
          src={
            pathname === "/taiyaki"
              ? "/img/sticky-nav/taiyaki_selected.png"
              : "/img/sticky-nav/taiyaki.png"
          }
          alt=""
          onClick={() => navigate("/taiyaki")}
        />
        <img
          className="mt-5 mb-6 mx-3 w-14 cursor-pointer"
          src={
            pathname === "/somisips"
              ? "/img/sticky-nav/sips_selected.png"
              : "/img/sticky-nav/sips.png"
          }
          alt=""
          onClick={() => navigate("/somisips")}
        />
      </div>

      {/* For smaller screens */}
      <div className="lg:hidden flex fixed bottom-0 flex-row z-[20] w-full bg-purple_3 border-t-purple_2 border-2 place-content-evenly">
        <img
          className="my-5 mx-3 w-16 cursor-pointer"
          src={
            pathname === "/softserve"
              ? "/img/sticky-nav/softserve_selected.png"
              : "/img/sticky-nav/softserve.png"
          }
          alt=""
          onClick={() => navigate("/softserve")}
        />
        <img
          className="my-5 mx-3 w-16 cursor-pointer"
          src={
            pathname === "/taiyaki"
              ? "/img/sticky-nav/taiyaki_selected.png"
              : "/img/sticky-nav/taiyaki.png"
          }
          alt=""
          onClick={() => navigate("/taiyaki")}
        />
        <img
          className="my-5 mx-3 w-16 cursor-pointer"
          src={
            pathname === "/somisips"
              ? "/img/sticky-nav/sips_selected.png"
              : "/img/sticky-nav/sips.png"
          }
          alt=""
          onClick={() => navigate("/somisips")}
        />
      </div>
    </>
  );
};

export default StickyMenuNav;
