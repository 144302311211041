import React, { useState, useEffect, useRef } from "react";

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const GoogleMap = ({ locations }) => {
  const mapRef = useRef(null); // Reference to the map DOM element

  useEffect(() => {
    const loadGoogleMaps = async () => {
      const { Map } = await window.google.maps.importLibrary("maps");
      const { Marker } = await window.google.maps.importLibrary("marker");

      const center = {
        lat: locations[Object.keys(locations)[0]].latitude,
        lng: locations[Object.keys(locations)[0]].longitude,
      };

      const map = new Map(mapRef.current, {
        zoom: 10,
        center: center,
        mapId: "somisomi_locations",
      });

      //   Add markers
      Object.keys(locations).map((location, index) => {
        const position = {
          lat: locations[location].latitude,
          lng: locations[location].longitude,
        };
        new Marker({
          map: map,
          position: position,
          icon: "/img/marker_.png",
        });
      });
    };

    // Load the Google Maps script
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&callback=initMap`;
    script.async = true;
    window.initMap = loadGoogleMaps; // The global function that will be called when the Google Maps script is loaded
    document.head.appendChild(script);

    return () => {
      // Cleanup
      window.initMap = null;
    };
  }, [locations]);

  return (
    <div id="map" ref={mapRef} className="w-2/3 rounded-xl h-96 md:mb-32"></div>
  );
};

export default GoogleMap;
