import React from "react";
import _ from "lodash"

const stringifyItem = (item) => {
  if (_.isString(item)) {
    return item
  }

  const flavors = item.flavor
  return flavors?.join(' & ')
}

const MenuOptions = ({ heading, items, onSelect, selectedItems, subHeading = "" }) => {
  return (
    <div className="flex flex-col md:w-2/3 w-11/12 md:mb-14 mb-4 border-b-2 border-purple_2 md:pb-14 md:pb-10 pb-4">
      <h1 className="text-xl text-grey_3 font-bilagro md:mb-10 mb-4">
        {heading}
        <br className="lg:hidden" />
        <span className="text-sm text-grey_3 font-montserrat mb-10">{subHeading}</span>
      </h1>
      <div className="flex lg:flex-row flex-wrap gap-y-6 gap-x-3">
        {items?.map((item, index) => (
          <div
            key={index}
            onClick={() => onSelect(item)}
            className={`border-2 hover:bg-purple_2 hover:border-purple_1 hover:text-purple_1 ${
              selectedItems === item || (Array.isArray(selectedItems) && selectedItems.includes(item))
                ? "bg-purple_2 border-purple_1 text-purple_1"
                : "border-grey_1 text-grey_1"
            } w-[30%] md:py-6 py-2 px-3 md:rounded-3xl rounded-xl flex items-center justify-center cursor-pointer text-center`}
          >
              <span className="flex justify-center lg:w-full md:text-base text-sm">{stringifyItem(item)}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MenuOptions;
