import React, { useEffect } from "react";
import _ from "lodash";

const MenuProductImage = ({ category, type, flavor, toppings, filling }) => {
  const flavors = _.isNull(flavor) ? [] : flavor?.flavor

  // For smaller screens, keeps the product image fixed at the top when scrolling down
  const handleScroll = () => {
    const remInPixels = parseFloat(getComputedStyle(document.documentElement).fontSize);
    const threshold = 25 * remInPixels;

    if (window.scrollY >= threshold) {
      toggleStyle();
    } else {
      document.querySelector(".child").classList.remove("fixPos");
      document.querySelector(".antichild").classList.add("dispNone");
    }
  };

  const toggleStyle = () => {
    document.querySelector(".child").classList.add("fixPos");
    document.querySelector(".antichild").classList.remove("dispNone");
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="md:w-1/2 w-full flex justify-center md:mb-14 mb-5 lg:translate-x-10 md:translate-x-5 child">
      
        <div className="w-full bg-white md:contents flex items-center justify-center md:p-0 p-5">
          <div className="relative sticky top-20 pd-div-width-sm pd-div-width-lg-m flex items-center justify-center rounded-2xl bg-[url('../public/img/product-bg.png')] bg-center bg-cover">
          {/* SOFTSERVES */}
          {category === "softserve" && type && (
            <>
              {/* FLAVOR */}
              {type === 'Ah Boong' && flavors.length === 0 && <img className="lg:w-[80%] w-[70%] absolute z-1" src={`/img/menu-softserve/flavors/${type}/base/Plain.png`} alt="" />}
              {type === 'Cup' && <img className="lg:w-[80%] w-[70%] absolute z-10" src={`/img/menu-softserve/flavors/${type}/base/Cup.png`} alt="" />}
              {type === 'Upside Down' && (
                <>
                  <img className="lg:w-[80%] w-[70%] absolute z-10" src={`/img/menu-softserve/flavors/${type}/base/Plain.png`} alt="" />
                  <img className="lg:w-[80%] w-[70%] absolute z-10" src={`/img/menu-softserve/flavors/${type}/base/Cup.png`} alt="" />
                </>
              )}
              {flavors.length > 1 ? (
                  <>
                    <img className="lg:w-[80%] w-[70%] absolute" src={`/img/menu-softserve/flavors/${type}/base/${flavors[0]}.png`} alt="" />
                    <img className="lg:w-[80%] w-[70%] absolute" src={`/img/menu-softserve/flavors/${type}/swirls/${flavors[1]}.png`} alt="" />
                  </>
              ) : (
              !_.isEmpty(flavors) ? (
                <img className="lg:w-[80%] w-[70%]" src={`/img/menu-softserve/flavors/${type}/base/${flavors[0]}.png`} alt="" />) : (<img className="lg:w-[80%] w-[70%] z-[-1]" src={`/img/menu-softserve/silhouettes/${type}.png`} alt="" />
                )
              )}

              {/* TOPPING */}
              {type && toppings?.map((topping, index) => <img key={index} className={`lg:w-[80%] w-[70%] absolute ${(topping === 'Strawberry' || topping === 'Macaron') && 'z-[-1]'}`} src={`/img/menu-softserve/toppings/${type}/${topping}.png`} alt="" />)}

              {/* FILLING */}
              {filling && <img className="lg:w-[80%] w-[70%] absolute" src={`/img/menu-softserve/fillings/${type}/${filling}.png`} alt="" />}
            </>
          )}

          {/* TAIYAKI */}
          {category === "taiyaki" && (type ? <img className={`lg:w-[80%] w-[70%] ${filling && 'lg:w-full w-full'}`} src={`/img/menu-taiyaki/${filling ? filling.replaceAll(" ", "").toLowerCase() : "Plain"}.png`} alt="" /> : <img className="lg:w-[80%] w-[70%]" src={`/img/menu-taiyaki/silhouettes/Taiyaki.png`} alt="" />)}

          {/* SOMISIPS */}
          {category === "somisips" && _.isNull(type) && <img className="h-[90%]" src={`/img/menu-somisips/silhouettes/Cold Brew.png`} alt="" />}
          {category === "somisips" && type && (
            <>
              {type !== "Cold Brew Float" && <img className="h-[90%]" src={`/img/menu-somisips/${type}.png`} alt="" />}
              {type === "Cold Brew Float" && (
                flavors?.length > 1 ?
                  (
                    <>
                      <img className="h-[90%] absolute" src={`/img/menu-somisips/${type}/base/${flavors[0]}.png`} alt="Cold Brew Float base" />
                      <img className="h-[90%] absolute" src={`/img/menu-somisips/${type}/swirls/${flavors[1]}.png`} alt="Cold Brew Float swirl" />
                      <img className="h-[90%] absolute" src={`/img/menu-somisips/${type}/base/Cup.png`} alt="Cold Brew Float Cup" />
                    </>
                  )
                    :
                  (!_.isEmpty(flavors) ?
                    <>
                      <img className="h-[90%] absolute" src={`/img/menu-somisips/${type}/base/${flavors[0]}.png`} alt="Cold Brew Float base" />
                      <img className="h-[90%] absolute" src={`/img/menu-somisips/${type}/base/Cup.png`} alt="Cold Brew Float Cup" />
                    </>
                      :
                    <>
                      <img className="h-[90%] absolute" src={`/img/menu-somisips/silhouettes/Cold Brew Float.png`} alt="" />
                      <img className="h-[90%] absolute" src={`/img/menu-somisips/Cold Brew Float/Cup.png`} alt="" />
                    </>
                  )
                )
              }
            </>
          )}
        </div>
      </div>
        </div>
      <div className="antichild"></div>
    </>
  );
};

export default MenuProductImage;
