import React, { useState, useEffect } from "react";
import Title from "../components/Title";
import StickyMenuNav from "../components/StickyMenuNav";
import MenuProductImage from "../components/MenuProductImage";
import MenuOptions from "../components/MenuOptions";
import LocationDropdown from "../components/LocationDropdown";
import NutritionFacts from "../components/NutritionFacts";
import { rtdb } from "../firebase";
import { ref, get, child } from "firebase/database";

const SoftServeMenu = () => {
  const [selectedSoftServe, setSelectedSoftServe] = useState(null);
  const [selectedFilling, setSelectedFilling] = useState(null);
  const [selectedToppings, setSelectedToppings] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedFlavor, setSelectedFlavor] = useState(null);
  const [flavorsData, setFlavorsData] = useState(null);
  const [flavors, setFlavors] = useState([])

  const getFlavorsData = async () => {
    const dbRef = ref(rtdb);

    get(child(dbRef, "somisomi/flavors"))
      .then((snapshot) => {
        if (snapshot.exists()) {
          setFlavorsData(snapshot.val());
        } else {
          console.log("Flavors data not available.");
        }
      })
      .catch((error) => {
        console.log("Flavors data not available.");
      });
  };

  useEffect(() => {
    getFlavorsData();
  }, []);

  useEffect(() => {
    if (selectedLocation !== null) {
      const { flavors } = flavorsData[selectedLocation.replace(/ /g, "").toLowerCase()]
      const { list } = flavors

      setFlavors(list)
    }
  }, [flavorsData, selectedLocation])

  const handleSoftServeSelect = (type) => {
    if (selectedSoftServe && selectedSoftServe === type) {
      setSelectedSoftServe(null);
    } else {
      if (type === "Cup") {
        setSelectedFilling(null);
      }
      setSelectedSoftServe(type);
    }
  };

  const handleFillingSelect = (filling) => {
    if (selectedFilling && selectedFilling === filling) {
      setSelectedFilling(null);
    } else {
      setSelectedFilling(filling);
    }
  };

  const toggleMacaronOrStrawberrySelect = (topping, previousTopping) => {
    const toppingsExceptMacaron = selectedToppings.filter((item) => item !== previousTopping)
    setSelectedToppings([...toppingsExceptMacaron, topping])
  }

  const checkToppingToBeToggled = (topping) => topping === "Macaron" || topping === "Strawberry"

  const handleToppingSelect = (topping) => {
    if (selectedToppings.includes(topping)) {
      setSelectedToppings(selectedToppings.filter((item) => item !== topping))
      return
    }

    if (checkToppingToBeToggled(topping)) {
      const previousTopping = selectedToppings.find(item => checkToppingToBeToggled(item))
      if (previousTopping !== undefined) {
        toggleMacaronOrStrawberrySelect(topping, previousTopping)
        return
      }
    }
    
    setSelectedToppings([...selectedToppings, topping])
  };

  const handleLocationSelect = (location) => {
    setSelectedFlavor(null);
    setSelectedLocation(location);
  };

  const handleFlavorSelect = (flavor) => {
    if (selectedFlavor && selectedFlavor === flavor) {
      setSelectedFlavor(null);
    } else {
      setSelectedFlavor(flavor);
    }
  };

  const soft_serve_types = ["Ah Boong", "Cup", "Upside Down"];
  const fillings = ["Nutella", "Custard", "Red Bean", "Matcha Custard", "Black Sesame"];
  const toppings = [
    "Biscoff Crumble",
    "Oreo Crumbs",
    "Macaron",
    "Captain Crunch",
    "Cocoa Pebbles",
    // "Cotton Candy Crunch", //REMOVED 01/31/2024
    "Rainbow Sprinkles",
    "Matcha Powder",
    "Strawberry",
    "Coconut Flakes",
    "Graham Cracker Crumbs",
    "Fruity Pebbles",
  ];

  return (
    <>
      <Title text="SOFT SERVE" isMenu={true} />
      <div className="w-full flex md:flex-row flex-col font-montserrat">
        <StickyMenuNav />
        <MenuProductImage
          type={selectedSoftServe}
          flavor={selectedFlavor}
          toppings={selectedToppings}
          filling={selectedFilling}
          category="softserve"
        />

        {/* Menu */}
        <div className="lg:w-1/2 md:w-1/2 w-full flex flex-col items-center lg:-translate-x-10 md:-translate-x-10">
          {flavorsData ? (
            <LocationDropdown
              selectedLocation={selectedLocation}
              onSelect={handleLocationSelect}
            />
          ) : (
            <div className="flex flex-col md:w-2/3 w-11/12 mb-14 border-b-2 border-purple_2 pb-5">
              <h1 className="text-xl text-grey_3 font-bilagro mb-10">
                LOADING LOCATIONS...
              </h1>
            </div>
          )}

          <MenuOptions
            heading="SELECT SOFT SERVE TYPE"
            items={soft_serve_types}
            onSelect={handleSoftServeSelect}
            selectedItems={selectedSoftServe}
          />

          {selectedLocation && (
            <MenuOptions
              heading="CHOOSE A FLAVOR"
              items={flavors}
              onSelect={handleFlavorSelect}
              selectedItems={selectedFlavor}
            />
          )}

          {!(selectedSoftServe === "Cup") && (
            <MenuOptions
              heading="CHOOSE A FILLING"
              items={fillings}
              onSelect={handleFillingSelect}
              selectedItems={selectedFilling}
            />
          )}

          <MenuOptions
            heading="ADD TOPPINGS"
            items={toppings}
            onSelect={handleToppingSelect}
            selectedItems={selectedToppings}
          />

          <NutritionFacts
            category="softserve"
            type={selectedSoftServe}
            flavor={selectedFlavor}
            filling={selectedFilling}
            toppings={selectedToppings}
          />
        </div>
      </div>
    </>
  );
};

export default SoftServeMenu;
