import React, { useState, useEffect } from "react";

import Title from "../components/Title";
import GoogleMap from "../components/GoogleMap";
import { rtdb } from "../firebase";
import { ref, get, child } from "firebase/database";

const Location = () => {
  const [uniqueStates, setUniqueStates] = useState([]);
  const [addressesInSelectedState, setAddressesInSelectedState] = useState({});
  const [selectedState, setSelectedState] = useState(null);
  const [showLocationDropdown, setShowLocationDropdown] = useState(false);
  const [locationsData, setLocationsData] = useState(null);
  const [hoursData, setHoursData] = useState(null);

  const getLocationData = async () => {
    const dbRef = ref(rtdb);

    get(child(dbRef, "somisomi/address"))
      .then((snapshot) => {
        if (snapshot.exists()) {
          setLocationsData(snapshot.val());
          setUniqueStates([
            ...new Set(Object.values(snapshot.val()).map((loc) => loc.state)),
          ]);
        } else {
          console.log("Locations data not available.");
        }
      })
      .catch((error) => {
        console.log("Locations data not available.");
      });
  };

  const getHoursData = async () => {
    const dbRef = ref(rtdb);

    get(child(dbRef, "somisomi/hours"))
      .then((snapshot) => {
        if (snapshot.exists()) {
          setHoursData(snapshot.val());
        } else {
          console.log("Hours data not available.");
        }
      })
      .catch((error) => {
        console.log("Hours data not available.");
      });
  };

  useEffect(() => {
    getLocationData();
    getHoursData();
  }, []);

  const US_STATES = {
    AL: "Alabama",
    AK: "Alaska",
    AZ: "Arizona",
    AR: "Arkansas",
    CA: "California",
    CO: "Colorado",
    CT: "Connecticut",
    DE: "Delaware",
    FL: "Florida",
    GA: "Georgia",
    HI: "Hawaii",
    ID: "Idaho",
    IL: "Illinois",
    IN: "Indiana",
    IA: "Iowa",
    KS: "Kansas",
    KY: "Kentucky",
    LA: "Louisiana",
    ME: "Maine",
    MD: "Maryland",
    MA: "Massachusetts",
    MI: "Michigan",
    MN: "Minnesota",
    MS: "Mississippi",
    MO: "Missouri",
    MT: "Montana",
    NE: "Nebraska",
    NV: "Nevada",
    NH: "New Hampshire",
    NJ: "New Jersey",
    NM: "New Mexico",
    NY: "New York",
    NC: "North Carolina",
    ND: "North Dakota",
    OH: "Ohio",
    OK: "Oklahoma",
    OR: "Oregon",
    PA: "Pennsylvania",
    RI: "Rhode Island",
    SC: "South Carolina",
    SD: "South Dakota",
    TN: "Tennessee",
    TX: "Texas",
    UT: "Utah",
    VT: "Vermont",
    VA: "Virginia",
    WA: "Washington",
    WV: "West Virginia",
    WI: "Wisconsin",
    WY: "Wyoming",
  };

  const DAYS = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  const findMapUrlByZipcode = (zip) => {
    const locations = Object.values(locationsData)
    const stringifyZip = typeof zip === 'number' ? zip.toString() : typeof zip === 'string' ? zip : ''
    const reformattedZip = stringifyZip.split('-')[0]
    const found = locations.find(item => reformattedZip === item["zip"])

    return {
      googleMap: found["google_map"] ?? '',
      yelp: found["yelp"] ?? ''
    }
  }

  return (
    <>
      <Title text="LOCATIONS" />

      <div className="w-full flex flex-col items-center font-montserrat">
        {!locationsData && (
          <div className="flex flex-col w-4/5 lg:w-2/3 mb-20 bg-purple_3 py-3 px-6 rounded-2xl text-purple_1">
            Loading locations...
          </div>
        )}

        {locationsData && (
          <div className="flex flex-col w-4/5 lg:w-2/3 mb-20">
            {/* BUTTON FOR THE DROPDOWN */}
            <button
              onClick={() => setShowLocationDropdown(!showLocationDropdown)}
              type="button"
              className="bg-purple_3 py-3 px-6 rounded-2xl text-purple_1 w-full flex flex-row justify-between focus:outline-none"
            >
              {selectedState ? US_STATES[selectedState] : "Select a state"}
              <svg
                className="-mr-1 ml-2 h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>

            {/* THE DROPDOWN */}
            {showLocationDropdown && (
              <div className="bg-purple_3 text-grey_1 rounded-2xl py-5 px-4 mt-3 h-[17rem] overflow-y-scroll flex flex-col justify-between">
                {uniqueStates.map((state, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      setShowLocationDropdown(false);
                      setSelectedState(state);
                      setAddressesInSelectedState(
                        Object.fromEntries(
                          Object.entries(locationsData).filter(
                            ([key, value]) => value.state === state
                          )
                        )
                      );
                    }}
                    className="px-5 py-3 hover:bg-purple_2 hover:rounded-xl hover:text-purple_1 hover:cursor-pointer"
                  >
                    {US_STATES[state]}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}

        {/* LOCATION CARDS */}
        {Object.keys(addressesInSelectedState)
          .sort((location1, location2) => addressesInSelectedState[location1]["name"].localeCompare(addressesInSelectedState[location2]["name"]))
          .map((address, index) => (
            <>
              {/* FOR LARGER SCREENS */}
              <div className="hidden lg:flex flex-row w-2/3 bg-purple_3 rounded-xl mb-10">
                {/* ADDRESS CARD */}
                <div className="border-r-purple_2 border-r-2 w-1/2 flex-col gap-y-6 flex items-end justify-end py-8 pr-4">
                  <div className="flex flex-col p-10">
                    {/* NAME */}
                    {addressesInSelectedState[address]
                      .name
                      .replace("SOMISOMI ", "")
                      .split("-")
                      .map(name => (
                        <h1 className="text-right text-purple_1 font-bilagro text-3xl">
                          {name}
                        </h1>
                      ))
                    }
                    {/* ADDRESS */}
                    <span className="text-right text-sm text-purple_2 mt-7">
                      {addressesInSelectedState[address]["street1"]}{" "}
                      {addressesInSelectedState[address]["street2"]}
                    </span>
                    <span className="text-right text-sm text-purple_2 mb-10">
                      {addressesInSelectedState[address].city},{" "}
                      {addressesInSelectedState[address].state}{" "}
                      {addressesInSelectedState[address].zip}
                    </span>
                  </div>
                  {/* MAP ICONS */}
                  <div className="flex justify-end gap-x-3 pr-10 pb-10">
                    {
                      findMapUrlByZipcode(addressesInSelectedState[address].zip).googleMap &&
                      <img
                        className="w-8 cursor-pointer"
                        onClick={() => window.open(findMapUrlByZipcode(addressesInSelectedState[address].zip).googleMap, "_blank")}
                        src="/img/locations/google-maps-icon.png"
                        alt="google map"
                      />
                    }
                    {
                      findMapUrlByZipcode(addressesInSelectedState[address].zip).yelp &&
                      <img
                        className="w-8 cursor-pointer"
                        onClick={() => window.open(findMapUrlByZipcode(addressesInSelectedState[address].zip).yelp, "_blank")}
                        src="/img/locations/yelp-icon.png"
                        alt="yelp"
                      />
                    }
                  </div>
                </div>
                {/* TIMING AND CONTACT CARD */}
                <div className="w-1/2 flex items-center justify-center pt-8 pb-8 ">
                  <div className="flex flex-col p-10 lg:items-start lg:justify-start md:items-center md:justify-center lg:mr-3">
                    {/* REGULAR HOURS */}
                    <h1 className="text-purple_1 font-montserrat-semibold text-xl mb-2">
                      REGULAR HOURS:
                    </h1>
                    {hoursData[address] ? (
                      DAYS.map((day, index) => {
                        const { opening, closing } = hoursData[address][day];
                        return (
                          <div
                            className="w-full flex justify-between mb-2"
                            key={index}
                          >
                            {/* DAY */}
                            <span className="text-sm text-purple_2 mr-5">
                              {day.charAt(0).toUpperCase() + day.slice(1)}
                            </span>
                            {/* TIME */}
                            <span className="text-sm text-purple_2">
                              {opening} - {closing}
                            </span>
                          </div>
                        );
                      })
                    ) : (
                      // N/A IF NO HOURS DATA AVAILABLE
                      <span className="text-sm text-purple_2">N/A</span>
                    )}
                    {/* SPECIAL HOURS */}
                    {hoursData[address] && hoursData[address]["special_hours"] ? (
                      <>
                        <h1 className="text-purple_1 font-montserrat-semibold text-xl mb-2 mt-8">
                          SPECIAL HOURS:
                        </h1>
                        {hoursData[address]["special_hours"].map(
                          (special_hour, index) => {
                            const { date, is_open, opening, closing } =
                              special_hour;

                            return (
                              <div
                                className="w-full flex justify-between mb-2"
                                key={index}
                              >
                                {/* DATE */}
                                <span className="text-sm text-purple_2 mr-5">
                                  {date}
                                </span>
                                {/* TIME */}
                                <span className="text-sm text-purple_2">
                                  {is_open ? `${opening} - ${closing}` : "CLOSED"}
                                </span>
                              </div>
                            );
                          }
                        )}
                      </>
                    ) : null}
                    {/* CONTACT */}
                    <h1 className="text-purple_1 font-montserrat-semibold text-xl mb-2 mt-8">
                      CONTACT:
                    </h1>
                    <span className="text-sm text-purple_2">
                      {addressesInSelectedState[address].phone.replace(
                        /^(\d{3})(\d{3})(\d{4})$/,
                        "($1) $2-$3"
                      )}
                      {/* Converts 2135055926 to (213) 505-5926 */}
                    </span>
                  </div>
                </div>
              </div>

              {/* FOR SMALLER SCREENS */}
              <div className="lg:hidden flex flex-col w-4/5 bg-purple_3 rounded-xl mb-10">
                <div className="flex items-center justify-center pt-8 pb-8">
                  <div className="flex flex-col p-10 items-center justify-center">
                    {/* NAME */}
                    {addressesInSelectedState[address]
                      .name
                      .replace("SOMISOMI ", "")
                      .split("-")
                      .map(name => (
                        <h1 className="text-center text-purple_1 font-bilagro text-3xl">
                          {name}
                        </h1>
                      ))
                    }
                    {/* ADDRESS */}
                    <span className="text-center text-sm text-purple_2 mt-7">
                      {addressesInSelectedState[address]["street1"]}{" "}
                      {addressesInSelectedState[address]["street2"]}
                    </span>
                    <span className="text-center text-sm text-purple_2 mb-10">
                      {addressesInSelectedState[address].city},{" "}
                      {addressesInSelectedState[address].state}{" "}
                      {addressesInSelectedState[address].zip}
                    </span>
                    {/* MAP ICONS */}
                    <div className="flex justify-end gap-x-3 pb-10">
                    {
                      findMapUrlByZipcode(addressesInSelectedState[address].zip).googleMap &&
                      <img
                        className="w-8 cursor-pointer"
                        onClick={() => window.open(findMapUrlByZipcode(addressesInSelectedState[address].zip).googleMap, "_blank")}
                        src="/img/locations/google-maps-icon.png"
                        alt="google map"
                      />
                    }
                    {
                      findMapUrlByZipcode(addressesInSelectedState[address].zip).yelp &&
                      <img
                        className="w-8 cursor-pointer"
                        onClick={() => window.open(findMapUrlByZipcode(addressesInSelectedState[address].zip).yelp, "_blank")}
                        src="/img/locations/yelp-icon.png"
                        alt="yelp"
                      />
                    }
                  </div>
                    {/* REGULAR HOURS */}
                    <h1 className="text-center text-purple_1 font-montserrat-semibold text-xl mb-2">
                      REGULAR HOURS:
                    </h1>
                    <div className="w-[15rem] text-center">
                      {hoursData[address] ? (
                        DAYS.map((day, index) => {
                          const { opening, closing } = hoursData[address][day];
                          return (
                            <div
                              className="w-full flex justify-between mb-2"
                              key={index}
                            >
                              {/* DAY */}
                              <span className="text-sm text-purple_2 mr-5">
                                {day.charAt(0).toUpperCase() + day.slice(1)}
                              </span>
                              {/* TIME */}
                              <span className="text-sm text-purple_2">
                                {opening} - {closing}
                              </span>
                            </div>
                          );
                        })
                      ) : (
                        <span className="text-sm text-purple_2 text-center">
                          N/A
                        </span>
                      )}
                    </div>
                    {/* SPECIAL HOURS */}
                    {hoursData[address] && hoursData[address]["special_hours"] ? (
                      <>
                        <h1 className="text-center text-purple_1 font-montserrat-semibold text-xl mt-8 mb-2">
                          SPECIAL HOURS:
                        </h1>
                        <div className="w-[15rem] text-center">
                          {hoursData[address]["special_hours"].map(
                            (special_hour, index) => {
                              const { date, is_open, opening, closing } =
                                special_hour;

                              return (
                                <div
                                  className="w-full flex justify-between mb-2"
                                  key={index}
                                >
                                  {/* DATE */}
                                  <span className="text-sm text-purple_2 mr-5">
                                    {date}
                                  </span>
                                  {/* TIME */}
                                  <span className="text-sm text-purple_2">
                                    {is_open
                                      ? `${opening} - ${closing}`
                                      : "CLOSED"}
                                  </span>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </>
                    ) : null}
                    {/* CONTACT */}
                    <h1 className="text-center text-purple_1 font-montserrat-semibold text-xl mb-2 mt-8">
                      CONTACT:
                    </h1>
                    <span className="text-center text-sm text-purple_2 mb-10">
                      {addressesInSelectedState[address].phone.replace(
                        /^(\d{3})(\d{3})(\d{4})$/,
                        "($1) $2-$3"
                      )}
                      {/* Converts 2135055926 to (213) 505-5926 */}
                    </span>
                  </div>
                </div>
              </div>
            </>
          ))}

        {/* MAP */}
        {selectedState && <GoogleMap locations={addressesInSelectedState} />}
      </div>
    </>
  );
};

export default Location;
