import React from "react";

const Title = ({ text, isMenu = false }) => {
  return (
    <div className="flex flex-col items-center justify-center bg-grad lg:p-14 p-10 lg:mb-14 mb-0 md:mt-0 mt-2">
      <h1
        className={`md:text-6xl text-3xl font-bilagro ${
          isMenu ? "text-purple_1" : "text-black"
        } font-bold text-center`}
      >
        {text}
      </h1>
    </div>
  );
};

export default Title;
