import React from "react";
import { useNavigate } from "react-router-dom";
import Slideshow from "./Slideshow";

const ContentCard = ({ images, background, size, title, description, order, button, number }) => {
  const navigate = useNavigate();

  return (
    <div className="flex md:flex-row flex-col items-center justify-between lg:w-[80%] md:w-[90%] lg:mb-20 mb-10">
      <div
        className={`w-1/2 flex items-center justify-center ${order[0]} ${background && "bg-[url('../public/img/product-bg.png')] rounded-2xl pd-div-width-lg-m pd-div-width-sm bg-center bg-cover pt-0"}`}
      >
        {images.length > 1 ? <Slideshow images={images} size={size} background={background} /> : <img className="rounded-[5%]" src={images[0].src} alt={images[0].alt} />}
        {/* {background && <img className="z-[-1]" src="/img/product-bg.png" alt="background" />} */}
      </div>
      <div
        className={`md:w-1/2 w-full p-10 flex flex-col md:items-start items-center justify-center md:text-left text-center ${order[1]}`}
      >
        {number && (
          <div className="w-10 h-10 md:p-6 p-8 mb-7 flex items-center justify-center rounded-full font-bilagro bg-purple_2 text-purple_1 md:text-base text-2xl">
            {number}
          </div>
        )}
        <div className={`font-bilagro ${title.size} mb-7 text-purple_1`}>
          {title.text}
        </div>
        <div
          className="md:text-sm"
          dangerouslySetInnerHTML={{ __html: description }}
        ></div>
        {button && (
          <button
            onClick={() => navigate(button.link)}
            className="px-8 py-3 mt-4 font-montserrat-semibold text-xs bg-purple_1 text-white rounded-full"
          >
            {button.text}
          </button>
        )}
      </div>
    </div>
  );
};

export default ContentCard;
