import React, { useState } from "react";

const FaqComponent = ({ faqs }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  return (
    <div className="w-full flex items-center justify-center font-montserrat text-black md:mb-40 mb-20">
      <div className="lg:w-1/2 md:w-4/5 w-[90%] md:text-base text-sm">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className="bg-purple_3 py-6 px-6 rounded-2xl w-full mb-5"
          >
            <div className="flex flex-row justify-between">
              <span className="w-[80%]">{faq.q}</span>
              <span className="w-[10%] flex items-center justify-center">
                <img
                  onClick={() =>
                    setActiveIndex(index === activeIndex ? null : index)
                  }
                  className="cursor-pointer"
                  src={
                    index === activeIndex ? "/img/minus.png" : "/img/plus.png"
                  }
                  alt=""
                />
              </span>
            </div>
            {index === activeIndex && (
              <div className="mt-4 text-purple_1">{faq.a}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FaqComponent;
