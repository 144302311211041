import React from "react";
import Title from "../components/Title";
import ContentCard from "../components/ContentCard";

const LabStore = () => {
  const sliderImages = [
    {
      src: `/img/menu-softserve/silhouettes/AhBoongSilhouetteOnly.png`,
      alt: "Ah Boong Silhouette"
    },
    {
      src: '/img/menu-softserve/silhouettes/CupSilhouetteOnly.png',
      alt: 'Cup Silhouette'
    },
    {
      src: '/img/menu-softserve/silhouettes/UpsideDownSilhouetteOnly.png',
      alt: 'Upside Down Silhouette'
    },
    {
      src: '/img/menu-somisips/silhouettes/Cold Brew Float.png',
      alt: 'Cold Brew Float Silhouette'
    },
    {
      src: '/img/menu-somisips/silhouettes/Cold Brew.png',
      alt: 'Cold Brew Silhouette'
    },
    {
      src: '/img/menu-somisips/silhouettes/Real Strawberry Milk.png',
      alt: 'Real Strawberry Milk Silhouette'
    },
    {
      src: '/img/menu-taiyaki/silhouettes/Taiyaki.png',
      alt: 'Taiyaki Silhouette'
    },
  ]
  const cardContent = 
    {
      title: { text: "What is a Somisomi Lab Store?", size: "lg:text-5xl md:text-3xl text-3xl" },
      description:
        "When Somisomi was born, all experiments and development began at Somisomi's first store, Koreatown. <br> Now we want to continue that value under the name Lab Store at the Koreatown location. <br><br> Somisomi Lab store provides exclusive access to unreleased flavors, innovative options, and freshly developed products straight from our research and development phase. <br><br> Through this store, we aim to measure demand and gather valuable feedback directly from you, our customers, helping us decide whether to launch, refine, or discontinue products. <br><br> We want to know what you, the customers, want. We believe that this lab store could give us an insight into your minds.",
      order: ["order-2", "order-1"],
      button: null,
    }

  return (
    <>
      <Title text="LAB STORE" />

      <div className="w-full flex justify-center md:mb-20 mb-10">
        <video className="w-2/3 rounded-lg" controls autoPlay muted>
          <source
            src="https://firebasestorage.googleapis.com/v0/b/somisomi-website.appspot.com/o/videos%2Flabstore_promotion.mov?alt=media&token=f7cfa4c3-871b-4fd9-8205-7e92526d196b"
            type="video/mp4"
          />
        </video>
      </div>

      <div className="w-full flex flex-col items-center justify-center font-montserrat text-purple_2">
        <ContentCard
          images={sliderImages}
          background={true}
          size="md:w-full w-1/2"
          title={cardContent.title}
          description={cardContent.description}
          order={cardContent.order}
          button={cardContent.button}
        />
      </div>
    </>
  );
};

export default LabStore;
